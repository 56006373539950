import { API_ADDRESS } from 'config/config';
import axios from 'axios';
import { FetchedVCDataMultiNew, SowingData } from 'types/Types';

export const getVCDataByIpfsAddress = async (ipfsAddressHash: string, token: string) =>
    new Promise<FetchedVCDataMultiNew | null>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS}/vc/ipfsAddress/${ipfsAddressHash}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const getUserActivityLogs = async (token: string, params: any) =>
    new Promise<any>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS}/logs/`, {
                headers: { Authorization: `Bearer ${token}` },
                params: params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export class BlockISAPIService {
    public async getSowingAsync(userToken: string): Promise<SowingData[] | any> {
        axios
            .get(`${API_ADDRESS}/sowing`, { headers: { Authorization: `Bearer ${userToken}` } })
            .then((res) => {
                console.log('[BlockISAPIService] : GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('[BlockISAPIService] : Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }
                console.log('[BlockISAPIService] : Response from GET sowing', sowingArray);
                return sowingArray;
                //setSowing(sowingArray);
            })
            .catch((err) => {
                console.error(err);
                throw err;
            })
            .finally(() => {
                console.info('[BlockISAPIService] Finish get sowings');
            });
    }

    /* public getSowing(userToken: string): SowingData[]  {
        axios
            .get(`${API_ADDRESS}/sowing`, { headers: { Authorization: `Bearer ${userToken}` } })
            .then((res) => {
                console.log('[BlockISAPIService] : GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('[BlockISAPIService] : Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }
                console.log('[BlockISAPIService] : Response from GET sowing', sowingArray);
                return sowingArray;
                //setSowing(sowingArray);
            })
            .catch((err) => {
                console.error(err);
                return err;
            })
            /*.finally(() => {
                console.info('[BlockISAPIService] Finish get sowings');
            });
    }*/

    /*
      public async getSowingByIpfS(ipfsPath: string) {
        //setLoading(true);
        axios
          .get(`${API_ADDRESS}/sowing/${ipfsPath}`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
          .then((res) => {
            console.log('GET sowingIPFS RESPONSE: ', res);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => setLoading(false));
      };
    */
}
