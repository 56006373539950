import React, { ReactElement, useEffect, useState } from 'react';
import { LoadedFiles, SowingData } from 'types/Types';
import styled from 'styled-components';
import { ShowActivityDrawer } from '../ShowActivityDrawer';
import { getCropsNamesFromSowing } from '../../../helpers/helper-functions';
import { useTranslation } from 'react-i18next';
import { ShowActivityDrawerNew } from '../ShowActivityDrawerNew';

type OverviewFinishedSowingProps = {
    sowingData: SowingData;
    ipfsPaths: string[];
    isUpdate: boolean;
    files: LoadedFiles[];
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLink: React.Dispatch<React.SetStateAction<string | null>>;
    setImageLoading: React.Dispatch<React.SetStateAction<boolean> | false>;
};

type DataVC1 = {
    subject: {
        _id: string;
        place: string;
        date: string;
        GERK: string;
        comment: string;
    };
};

const OverviewFinishedSowing = ({
    sowingData,
    ipfsPaths,
    isUpdate,
    files,
    setSuccess,
    setLoading,
    setError,
    setTxLink,
    setImageLoading,
}: OverviewFinishedSowingProps): ReactElement => {
    const { t } = useTranslation();
    const [drawerIds, setDrawerIds] = useState<string[]>([]);

    useEffect(() => {
        if (sowingData.drawerIDs != undefined && sowingData.drawerIDs.length > 0) {
            console.warn('OverviewFinishedSowings DRAWER_IDS', sowingData);
            setDrawerIds(sowingData.drawerIDs);
        }
    }, []);

    return (
        <ConfirmSowingWrapper>
            {sowingData.crop && (
                <>
                    <div>
                        <strong>{t('sowing.common.itemName')}</strong> {getCropsNamesFromSowing(sowingData)}
                        <div>
                            <strong>{t('sowing.common.comment')}</strong> {sowingData.comment}
                        </div>
                        <div>
                            <strong>{t('sowing.common.nationalLocationId')}</strong> {sowingData.GERK}
                        </div>
                        <div>
                            <strong>{t('sowing.common.place')}</strong> {sowingData.location}
                        </div>
                        <div>
                            <strong>{t('sowing.common.date')}</strong> {sowingData.date}
                        </div>
                        <div>
                            <strong>{t('sowing.common.idsData')}</strong> {sowingData.drawerIDs + ' ' || ''}
                        </div>
                        <strong>{t('sowing.common.states')}</strong>
                        <ShowActivityDrawerNew activityId={sowingData.activity_id}></ShowActivityDrawerNew>
                        {/*drawerIds.map((id) => (
                            <>
                                <ShowActivityDrawer drawerId={id}></ShowActivityDrawer>
                            </>
                        ))*/}
                        {/*fileArray*/}
                    </div>
                </>
            )}
        </ConfirmSowingWrapper>
    );
};

export default OverviewFinishedSowing;

const ConfirmSowingWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const Image = styled.div`
    display: flex;
    width: 100px;
    height: 100px;
`;
const DrawerCard = styled.div`
    display: block;
    margin: 1em;
`;
