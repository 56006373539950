import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import CreateUser from 'ui/organisms/CreateUser/CreateUser';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import styled, { css } from 'styled-components';
import { Sled2Partners } from 'types/Types';
import SearchPartners from 'ui/organisms/SearchPartners/SearchPartners';
import { getPartners } from '../../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

const UserDashboardAdd = (): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [partners, setPartners] = useState<Sled2Partners[]>([]);
    const [selected, setSelected] = useState<number | null>(null);
    const { step, stepInc, stepDec } = useStep();
    const [selectedPartner, setSelectedPartner] = useState<any>(null);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const getPartnerFromId = () => {
        let data = null;
        for (let i = 0; i < partners.length; i++) {
            if (partners[i].source_system_user_id === selected?.toString()) {
                data = partners[i];
                break;
            }
        }
        return data;
    };

    useEffect(() => {
        getPartners()
            .then((res) => {
                setPartners(res.filter((partner: Sled2Partners) => partner.taxNumber));
            })
            .catch((err) => {
                console.error(err);
            });
        //getPartners();
    }, []);

    useEffect(() => {
        setSelectedPartner(getPartnerFromId());
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <UserDashboardAddWrapper>
                <ContainerMain>
                    {step === 0 && (
                        <SearchPartners partners={partners} selected={selected} onSelectionChanged={setSelected} />
                    )}
                    {step === 1 && <CreateUser partner={selectedPartner} />}
                </ContainerMain>
                <ContainerBottom>
                    <ContainerButton pos="left">
                        <Button disabled={step === 0} onClick={stepDec} text={t('navigation.back')} btnType="primary" />
                    </ContainerButton>
                    <ContainerButton pos="right">
                        <Button
                            disabled={!selectedPartner}
                            onClick={stepInc}
                            text={t('navigation.next')}
                            btnType="primary"
                        />
                    </ContainerButton>
                </ContainerBottom>
            </UserDashboardAddWrapper>
        </LoadingAnimationWrapper>
    );
};

export default UserDashboardAdd;

const UserDashboardAddWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
