import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { LoadedFiles, SowingData, UpdateSowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import SearchSowings from 'ui/organisms/SearchSowings/SearchSowings';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ConfirmSowing from 'ui/organisms/ConfirmSowing/ConfirmSowing';
import { UpdateSowing } from 'ui/organisms/UpdateSowing';
import { Title } from 'ui/atoms/Title';
import { GiConfirmed } from 'react-icons/gi';
import { AlertPopup } from 'ui/atoms/AlertPopup';
import { OverviewFinishedSowings } from 'ui/organisms/OverviewFinishedSowings';
import { getSowingBC, updateSowingSled2New } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

let initialStateSowingUpdateData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 1,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
    activity_id: 0,
};

const SowingUpdate: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [sowingDatas, setSowing] = useState<SowingData[]>([]);
    const [selected, setSelected] = useState<SowingData | null>(null);
    //let [selectedSowing, setSelectedSowing] = useState<SowingData>();
    const [sowingData, setSowingUpdateData] = useState<SowingData>(initialStateSowingUpdateData);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [files, setLoadedFiles] = useState<LoadedFiles[]>([]);
    const [txLink, setTxLink] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(true);
    const [showPictureAlert, setshowPictureAlert] = useState<boolean>(true);
    const [isValid, isAllInputValid] = useState<boolean>(false);
    //let selectedSowing: SowingData | null = null;

    const checkIfImagesNeedToRemoved = () => {
        if (step == 3) {
            setLoadedFiles([]);
        }
        stepDec();
    };

    const setSelectedSowingFromParcel = () => {
        console.log('TEst get sowing from ID:', selected);
        if (selected != null) {
            initialStateSowingUpdateData = selected;
        }
        console.log('SELECTED SOWING DATA:', initialStateSowingUpdateData);
    };

    const getSowing = useCallback(async () => {
        setLoading(true);

        getSowingBC(1)
            .then((res) => {
                setSowing(res);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, [sowingDatas]);

    const updateSowingToEnd = () => {
        console.log('Update sowing to end');
        if (selected != null) {
            const data: UpdateSowingData = {
                activity_id: selected.activity_id,
                ipfsPath: selected.ipfsAddress,
                status: 2,
            };
            updateSowingSled2New(data)
                .then((response) => {
                    setTxLink(response.txLink);
                    setSuccess(true);
                })
                .catch((error) => {
                    console.error('Error updating sowing to complete', error);
                });
        }
    };

    const showEndSowingAlertOrProceed = () => {
        console.log('showEndSowingAlertOrProceed');
        if (!confirmed) {
            setShowAlert(true);
        } else {
            updateSowingToEnd();
            setConfirmed(false);
        }
    };

    const showImageAlertOrProceed = () => {
        console.log('showImageAlertOrProceed');
        if (step === 2 && files.length === 0) {
            if (!confirmed) {
                setShowAlert(true);
            } else {
                setConfirmed(false);
                stepInc();
            }
        } else {
            stepInc();
        }
    };

    useEffect(() => {
        setSelectedSowingFromParcel();
    }, [selected]);

    useEffect(() => {
        setShowAlert(false);
        if (step === 0) {
            if (confirmed) {
                updateSowingToEnd();
                setConfirmed(false);
            }
        } else if (step === 2) {
            if (confirmed) {
                setConfirmed(false);
                stepInc();
            }
        }
    }, [confirmed]);

    useEffect(() => {
        getSowing();
    }, []);

    useEffect(() => {
        setSelectedSowingFromParcel();
        setSowingUpdateData({ ...initialStateSowingUpdateData });
        console.log('Sowing data UPDATED: ', sowingData);
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('sowingUpdate.title')}
                                                subtext={t('sowingUpdate.searchSowingSubtext')}
                                            />
                                            <SearchSowings
                                                onSelectionChanged={setSelected}
                                                sowings={sowingDatas}
                                                selected={selected}
                                            />
                                            <AlertPopup
                                                title={t('sowingUpdate.finishSowingQuestion')}
                                                message={t('sowingUpdate.finishSowingDescription')}
                                                isShown={showAlert}
                                                alertType="info"
                                                confirmed={confirmed}
                                                declineButtonText={t('alertPopup.declineButtonText')}
                                                confirmeButtonText={t('alertPopup.declineButtonText')}
                                                onDeclined={setShowAlert}
                                                onConfirmed={setConfirmed}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('sowingUpdate.title')}
                                                subtext={t('sowingUpdate.overviewFinishSowingDescription')}
                                            />
                                            <OverviewFinishedSowings
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={true}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 2 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('sowingUpdate.title')}
                                                subtext={t('sowingUpdate.addSowingActivityDescription')}
                                            />
                                            <UpdateSowing
                                                sowingData={sowingData}
                                                setAllInputIsValid={isAllInputValid}
                                                setSowingData={setSowingUpdateData}
                                                setIpfsPaths={setIpfsPaths}
                                                setLoadedFiles={setLoadedFiles}
                                            />
                                            <AlertPopup
                                                title={t('alertPopup.addSowingImageDesc')}
                                                message={t('alertPopup.addSowingImageMessage')}
                                                isShown={showAlert}
                                                confirmed={confirmed}
                                                declineButtonText={t('alertPopup.declineButtonText')}
                                                confirmeButtonText={t('alertPopup.confirmButtonText')}
                                                onDeclined={setShowAlert}
                                                onConfirmed={setConfirmed}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 3 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('sowingUpdate.title')}
                                                subtext={t('sowingUpdate.confirmSowingSubtext')}
                                            />
                                            <ConfirmSowing
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={true}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    {step !== 0 && (
                                        <ContainerButton pos="left">
                                            <Button
                                                onClick={checkIfImagesNeedToRemoved}
                                                disabled={step === 0}
                                                hidden={step === 0}
                                                iconPosition="left"
                                                icon={<GoArrowLeft />}
                                                text={t('navigation.back')}
                                                btnType="primary"
                                            />
                                        </ContainerButton>
                                    )}
                                    {step === 0 && (
                                        <ContainerButton pos="left">
                                            <Button
                                                onClick={() => showEndSowingAlertOrProceed()}
                                                disabled={selected === null}
                                                hidden={step === 0}
                                                iconPosition="left"
                                                icon={<GiConfirmed />}
                                                text={t('sowingUpdate.completeSowing')}
                                                btnType="primary"
                                            />
                                        </ContainerButton>
                                    )}
                                    <ContainerButton pos="right">
                                        <Button
                                            onClick={() => showImageAlertOrProceed()}
                                            disabled={selected === null || step === 3 || (step === 2 && !isValid)}
                                            iconPosition="right"
                                            icon={<GoArrowRight />}
                                            text={
                                                step === 0 ? t('sowingUpdate.addSowingActivity') : t('navigation.next')
                                            }
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message={t('sowingUpdate.successFinishedMessage')} txLink={txLink} />
                                {files.length != 0 && (
                                    <>
                                        {imageLoading && (
                                            <>
                                                <SuccessMessage
                                                    message={t('sowingUpdate.updateSowingSuccessDescriptionMessage')}
                                                />
                                            </>
                                        )}
                                        <LoadingAnimationWrapper loading={imageLoading}>
                                            <SuccessMessage
                                                message={t('sowingUpdate.updateSowingImageSuccessDescriptionMessage')}
                                            />
                                        </LoadingAnimationWrapper>
                                    </>
                                )}
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            text={t('navigation.addOneMoreActivity')}
                                            onClick={() => window.location.reload()}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text={t('navigation.moveToAHarvest')}
                                            onClick={() => history.push('/harvest')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text={t('navigation.moveToAHome')}
                                            onClick={() => history.push('/')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default SowingUpdate;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    @media screen and (max-width: 768px) {
        margin: 0 0 0 0;
    }
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
