import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import styled, { css } from 'styled-components';
import { ListUsers } from 'ui/organisms/List/Users';
import { User } from 'types/Types';
import { EditUserInfo } from 'ui/organisms/EditUserInfo';
import { getRegisteredUsers } from '../../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

const UserDashboardManage = (): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [registeredUsers, setRegisteredUsers] = useState<User[]>([]);
    const [selected, setSelected] = useState<number | null>(null);
    const { step, stepInc, stepDec } = useStep();
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const getUserFromId = () => {
        let data = null;
        for (let i = 0; i < registeredUsers.length; i++) {
            if (registeredUsers[i]._id === selected) {
                data = registeredUsers[i];
                break;
            }
        }
        return data;
    };

    useEffect(() => {
        getRegisteredUsers()
            .then((res) => {
                console.log(res);
                setRegisteredUsers(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        setSelectedUser(getUserFromId());
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <UserDashboardManageWrapper>
                <ContainerMain>
                    {step === 0 && (
                        <ListWrapper>
                            <ListUsers users={registeredUsers} selected={selected} onSelectionChanged={setSelected} />
                        </ListWrapper>
                    )}
                    {step === 1 && selectedUser && <EditUserInfo user={selectedUser} />}
                </ContainerMain>
                <ContainerBottom>
                    <ContainerButton pos="left">
                        <Button disabled={step === 0} onClick={stepDec} text={t('navigation.back')} btnType="primary" />
                    </ContainerButton>
                    <ContainerButton pos="right">
                        <Button
                            disabled={!selectedUser || step === 1}
                            onClick={stepInc}
                            text={t('navigation.next')}
                            btnType="primary"
                        />
                    </ContainerButton>
                </ContainerBottom>
            </UserDashboardManageWrapper>
        </LoadingAnimationWrapper>
    );
};

export default UserDashboardManage;

const UserDashboardManageWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    height: 0px;
    flex: 1 1 auto;
    overflow-y: hidden;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;

const ListWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;
