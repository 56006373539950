export const API_ADDRESS =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_ADDRESS_PRODUCTION
        : process.env.REACT_APP_API_ADDRESS_DEV;
export const API_ADDRESS_VASCO = process.env.VASCO_API_ADDRESS_PROD;
//export const API_ADDRESS_SLED2_CONF_HTTPS = 'https://blockisv2-api.azurewebsites.net';
//export const API_ADDRESS_SLED2_CONF_HTTP = 'http://blockisv2-api.azurewebsites.net';

export const API_ADDRESS_SLED2 =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_SLED2_API_ADDRESS_PRODUCTION
        : process.env.REACT_APP_SLED2_API_ADDRESS_TEST;
