import { Button } from 'ui/atoms/Button';
import React, { ReactElement } from 'react';
import { ImageInfoRequestData, LoadedFiles, SowingData, UpdateSowingData } from 'types/Types';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { getArrayOfCropsIdsForSowing, getCropsNamesFromSowing } from '../../../helpers/helper-functions';
import {
    addImagesInfosNew,
    addImagesNew,
    confirmSowingNew,
    sowingCreateDataSled2,
    updateSowingSled2New,
} from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

type ConfirmSowingProps = {
    sowingData: SowingData;
    ipfsPaths: string[];
    isUpdate: boolean;
    files: LoadedFiles[];
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLink: React.Dispatch<React.SetStateAction<string | null>>;
    setImageLoading: React.Dispatch<React.SetStateAction<boolean> | false>;
};

type DataVC1 = {
    subject: {
        products: string[];
        place: string;
        date: string;
        GERK: string;
        comment: string;
    };
};

const prepareFileImagesToRender = (filesIn: LoadedFiles[]) => {
    console.log('Files in confirmation', filesIn);
    const imageArray = [];
    for (const file of filesIn) {
        imageArray.push(<img src={file.preview} alt="" key={file.preview} />);
    }
    return imageArray;
};

const ConfirmSowing = ({
    sowingData,
    ipfsPaths,
    isUpdate,
    files,
    setSuccess,
    setLoading,
    setError,
    setTxLink,
    setImageLoading,
}: ConfirmSowingProps): ReactElement => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const fileArray = prepareFileImagesToRender(files);
    const confirmSowingHandler = () => {
        // TODO: Validate data before sending
        if (isUpdate) {
            const updateData: UpdateSowingData = {
                ipfsPath: sowingData.ipfsAddress,
                status: sowingData.status,
                activity_id: sowingData.activity_id,
            };

            updateSowing(updateData, files);
        } else {
            confirmSowing(
                {
                    subject: {
                        products: getArrayOfCropsIdsForSowing(sowingData),
                        place: sowingData.location,
                        date: sowingData.date,
                        GERK: sowingData.GERK,
                        comment: sowingData.comment,
                    },
                },
                files,
            );
        }
    };

    const updateSowing = (data: UpdateSowingData, files: LoadedFiles[]) => {
        setLoading(true);
        console.log('Update SOWING UPDATE: DATA: ', data);
        updateSowingSled2New(data)
            .then((res) => {
                setTxLink(res.txLink);
                sowingCreateDataNew(data.activity_id, files, data.ipfsPath);
                setSuccess(true);
            })
            .catch((err) => setError(err))
            .then(() => {
                setLoading(false);
            })
            .finally(() => {
                console.log('Finishing updating sowing');
            });
    };

    const confirmSowing = (data: DataVC1, files: LoadedFiles[]) => {
        setLoading(true);
        console.log('NEW SOWING: ', data);
        confirmSowingNew(data, files)
            .then((res) => {
                console.log('Response from POST sowing: ', res);
                setTxLink(res.txHash);
                console.log('Ipfs paths array 1', ipfsPaths);

                console.log('Inside', files.length != 0);
                setImageLoading(true);
                const timer = setTimeout(() => {
                    console.log('Ipfs paths array', files);
                    sowingCreateDataNew(res.activity_id, files, res.ipfsAddress);
                    console.log('This will run after 10s');
                }, 10000);
                setSuccess(true);
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                console.log('Finishing confirming sowing');
            });
    };

    const sowingCreateDataNew = (activityId: number, files: LoadedFiles[], ipfsPathIn?: string) => {
        //setLoading(true);
        console.log('CREATE SOWING DATA:');
        const imageInfo: ImageInfoRequestData = {
            id: '',
            description: sowingData.comment,
            inputDate: Math.floor(new Date(sowingData.date).getTime() / 1000).toString(),
            field1: '',
            field2: '',
            field3: '',
        };
        addImagesInfosNew(activityId, imageInfo)
            .then((resImageInfo) => {
                console.log('ImageInfoResponse: ', resImageInfo);
                if (files.length != 0) {
                    const form = new FormData();
                    //setLoading(true);
                    form.append('id', resImageInfo.image_info_id.toString());
                    for (let i = 0; i < files.length; i++) {
                        console.log('files description: ', files[i]);
                        form.append('images_ipfs', files[i].data, files[i].name);
                    }
                    console.log('AddImages RUnned');
                    addImagesNew(activityId, resImageInfo.image_info_id, form)
                        .then((res) => {
                            setImageLoading(false);
                            setTxLink(resImageInfo.txLink);
                        })
                        .catch((error) => setError(error));
                }
            })
            /*sowingCreateDataSled2(activityId, files, ipfsPathIn)
            .then((createDataResponse) => {
                console.log('SUCCESSfully created data ', createDataResponse);
                const drawerId = createDataResponse.id;
                const imageInfo: ImageInfoRequestData = {
                    id: drawerId,
                    description: sowingData.comment,
                    inputDate: Math.floor(new Date(sowingData.date).getTime() / 1000).toString(),
                    field1: '',
                    field2: '',
                    field3: '',
                };
                addImagesInfos(imageInfo)
                    .then((res) => {
                        if (files.length != 0) {
                            const form = new FormData();
                            //setLoading(true);
                            form.append('id', drawerId);
                            for (let i = 0; i < files.length; i++) {
                                form.append('images_ipfs', files[i].data);
                            }
                            addImages(form)
                                .then((res) => {
                                    setImageLoading(false);
                                    setTxLink(createDataResponse.txLink);
                                })
                                .catch((error) => setError(error));
                        }
                    })
                    .catch((error) => setError(error));
            })*/
            .catch((error) => setError(error))
            .finally(() => {
                //setLoading(false);
            });
    };

    return (
        <ConfirmSowingWrapper>
            {sowingData.crop && (
                <>
                    <div>
                        <strong>{t('sowing.common.itemName')}</strong> {getCropsNamesFromSowing(sowingData)}
                        <div>
                            <strong>{t('sowing.common.comment')}</strong> {sowingData.comment}
                        </div>
                        <div>
                            <strong>{t('sowing.common.nationalLocationId')}</strong> {sowingData.GERK}
                        </div>
                        <div>
                            <strong>{t('sowing.common.place')}</strong> {sowingData.location}
                        </div>
                        <div>
                            <strong>{t('sowing.common.date')}</strong> {sowingData.date}
                        </div>
                        {fileArray.length != 0 && (
                            <>
                                <strong>{t('sowing.common.images')}</strong>
                                <Image>{fileArray}</Image>
                            </>
                        )}
                    </div>
                    <BottomWrapper>
                        <Button
                            onClick={confirmSowingHandler}
                            text={t('navigation.confirm')}
                            btnType="primary-outline"
                        />
                    </BottomWrapper>
                </>
            )}
        </ConfirmSowingWrapper>
    );
};

export default ConfirmSowing;

const ConfirmSowingWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const Image = styled.div`
    display: flex;
    width: 200px;
    height: 200px;
`;
