import { useAuth } from 'contexts/AuthContext';
import React, { useState } from 'react';
import { Button } from 'ui/atoms/Button';
import Select from 'react-select';
import styled from 'styled-components';
import { CreateUserRequest, Sled2Partners } from '../../../types/Types';
import { createUserSled2 } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

export type CreateUserProps = {
    partner: Sled2Partners;
};

const options = [
    { value: 'PRIDELOVALEC', label: 'Pridelovalec' },
    { value: 'ADMIN', label: 'Admin' },
    { value: 'KOV', label: 'KOV' },
    { value: 'PREVOZNIK', label: 'Prevoznik' },
];

const CreateUser = ({ partner }: CreateUserProps): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [newUser, setNewUser] = useState<any>(null);
    const [userType, setUserType] = useState<{ value: string; label: string } | null>(null);

    const createUser = () => {
        const data: CreateUserRequest = {
            username: partner.taxNumber,
            email: partner.email ? partner.email : null,
            userType: userType?.value,
            sifra: parseInt(partner.source_system_user_id, 10), // TODO check if this works ok is possible to be string or works only with int
            name: partner.name,
            idCountry: parseInt(partner.country, 10),
        };
        console.log('Create user ', data);
        createUserSled2(data)
            .then((res) => {
                setNewUser({
                    name: partner.name,
                    username: partner.taxNumber,
                    password: res.password,
                });
            })
            .catch((err) => console.error(err));
    };

    return (
        <Div>
            {!newUser && (
                <>
                    <Wrapper>
                        <h3>{t('admin.createPartner.subtitle')}</h3>
                        <div>{partner.name}</div>
                    </Wrapper>
                    <StyledSelect value={userType} onChange={setUserType} options={options} />
                </>
            )}
            {newUser && (
                <Wrapper>
                    <h3>{t('admin.createPartner.successTitle')}</h3>
                    <div>
                        {t('admin.createPartner.name')} {newUser.name}
                    </div>
                    <div>
                        {t('admin.createPartner.username')} {newUser.username}
                    </div>
                    <div>
                        {t('admin.createPartner.password')} {newUser.password}
                    </div>
                </Wrapper>
            )}
            {!newUser && <StyledButton onClick={createUser} text={t('admin.createPartner.title')} btnType="primary" />}
        </Div>
    );
};

export default CreateUser;

const Wrapper = styled.div`
    margin: 10px;
`;

const StyledButton = styled(Button)`
    margin: 10px;
`;

const StyledSelect = styled(Select)`
    margin: 10px;
`;

const Div = styled.div`
    flex: 1 1 auto;
`;
