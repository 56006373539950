import React, { useState, useEffect, ReactElement } from 'react';
import { FetchedVCDataMulti } from 'types/Types';
import styled from 'styled-components';
import { Generator } from 'ui/organisms/QRCode';
import { ListPickupPrepared } from 'ui/organisms/List/PickupPrepared';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import { Title } from 'ui/atoms/Title';
import { getVC2 } from '../../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

const PickupPrepared = (): ReactElement => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [fetchedVCData, setFetchedVCData] = useState<FetchedVCDataMulti[] | null>(null);
    const [selectedFetchedVCData, setSelectedFetchedVCData] = useState<string | null>(null);
    const [showQR, setShowQR] = useState(false);
    const { loading, error, setLoading, setError } = useApiState();

    const getVCData = () => {
        setLoading(true);
        getVC2()
            .then((res) => {
                setFetchedVCData(res);
            })
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
    };

    const toggleQR = () => {
        setShowQR(!showQR);
    };

    useEffect(() => {
        getVCData();
    }, []);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <PickupPreparedWrapper>
                <Title text={t('pickupPrepared.title')}></Title>
                <ContainerMain>
                    {!showQR && fetchedVCData && (
                        <ListPickupPrepared
                            fetchedPickupData={fetchedVCData}
                            selected={selectedFetchedVCData}
                            onSelectionChanged={setSelectedFetchedVCData}
                        />
                    )}
                    {showQR && selectedFetchedVCData && <Generator ipfsPath={selectedFetchedVCData} />}
                </ContainerMain>
            </PickupPreparedWrapper>
        </LoadingAnimationWrapper>
    );
};

export default PickupPrepared;

const PickupPreparedWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;
