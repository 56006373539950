import React, { ReactElement, useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { formatDateToString } from 'helpers/helper-functions';
import { getActivityHistory, getImages, getImagesInfo } from '../../../services/sled2-api';
import { ActivityHistory } from '../../../types/Types';

type ShowActivityDrawerProps = {
    activityId: number;
};

type DrawerImagesIpfs = {
    id: string;
    imagesIpfs: string[];
    imageInfo: any;
};

/*const initialActivityHistory = {
    activity_id: 0,
    activity_name: "string",
    activity_description: "string",
    activity_created: "string",
    activity_image_info_id: 0,
    activity_subDescription: "string",
    activity_sub_creation: "string",
    activity_images : [],
}*/

/*const prepareFileImagesToRender = async (activityId: string) => {
    //console.log('Files in confirmation', drawerId);
    let drawerImagesIpfs: DrawerImagesIpfs;
    try {
        const imgResponse = await getActivityHistory(activityId);
        //const imageInfo = await getImagesInfo(drawerId);
        //console.log('Await response ', response);
        drawerImagesIpfs = {
            id: drawerId,
            imagesIpfs: imgResponse.ipfsPaths,
            imageInfo: imageInfo,
        };
        return drawerImagesIpfs;
    } catch (err) {
        //console.log('Error has occured getting images from drawer id:', drawerId);
    }
};
const timestampToDate = (timestamp: string | null): any => {
    if (timestamp != undefined && timestamp != null && timestamp.length != 0) {
        const date = new Date(parseInt(timestamp) * 1000);
        return formatDateToString(date, false);
    }
    return 'BrezDatuma';
};*/

const ShowActivityDrawerNew = ({ activityId }: ShowActivityDrawerProps): ReactElement => {
    const { currentUser } = useAuth();
    const [drawerData, setDrawerData] = useState<DrawerImagesIpfs>();
    const [historyItems, setHistoryItems] = useState<ActivityHistory[]>([]);

    useEffect(() => {
        //console.log('OverviewFinishedSowings DRAWER_IDS', drawerId);
        if (activityId !== undefined) {
            getActivityHistory(activityId)
                .then((item) => {
                    console.log('Returned DRAWER items: ', item);
                    setHistoryItems(item);
                })
                .catch((err) => {
                    console.error('Error');
                });
        }
    }, []);

    return (
        <ShowActivityDrawerWrapper>
            {historyItems != undefined && (
                <>
                    {historyItems.map((actHis, i) => (
                        <>
                            <Test>
                                {actHis.activity_subDescription != null
                                    ? actHis.activity_subDescription
                                    : actHis.activity_description}
                                <br></br>
                                {actHis.activity_sub_creation != null
                                    ? actHis.activity_sub_creation
                                    : actHis.activity_created}
                            </Test>

                            {actHis.activity_images != undefined && (
                                <DrawerCard>
                                    {actHis.activity_images.map(
                                        (image, index) =>
                                            image != null &&
                                            image != '' && (
                                                <Image key={actHis.activity_image_info_id + index}>
                                                    <img
                                                        src={image}
                                                        alt=""
                                                        key={actHis.activity_image_info_id + index}
                                                    />
                                                </Image>
                                            ),
                                    )}
                                </DrawerCard>
                            )}
                        </>
                    ))}
                </>
            )}
        </ShowActivityDrawerWrapper>
    );
};

export default ShowActivityDrawerNew;

const ShowActivityDrawerWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const DrawerCard = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1em;
`;

const Image = styled.div`
    display: flex;
    width: 200px;
    height: 100px;
    max-height: 100px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 5px;
`;

const Test = styled.div``;
