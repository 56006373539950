import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SowingData } from 'types/Types';
import { Input } from 'ui/molecules/Input';
import ListSowings from '../List/Sowings/ListSowings';
import { getCropsNamesFromSowing } from '../../../helpers/helper-functions';
import { useTranslation } from 'react-i18next';

type SearchSowingProps = {
    sowings: SowingData[];
    selected: SowingData | null;
    onSelectionChanged: React.Dispatch<React.SetStateAction<SowingData | null>>;
};

const SearchSowings = ({ sowings, selected, onSelectionChanged }: SearchSowingProps): ReactElement => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [filteredSowings, setFilteredSowing] = useState<SowingData[]>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        setFilteredSowing(sowings);
    }, [sowings]);

    useEffect(() => {
        console.log('Sowings array of Data', sowings);
        let response = false;
        if (sowings !== undefined && sowings.length !== 0) {
            setFilteredSowing(
                sowings.filter((sowing) => {
                    console.log('Sowing item', sowing);
                    if (sowing) {
                        response =
                            sowing.place.toLowerCase().includes(search.toLowerCase()) ||
                            getCropsNamesFromSowing(sowing).toLowerCase().includes(search.toLowerCase()) ||
                            sowing.GERK.toLowerCase().includes(search.toLowerCase()) ||
                            false;
                    }
                    /*if (!response && sowing.crop?.name) {
                        console.log('Searching by crop_name', response);
                        response = 
                    }*/
                    console.log('Searching by key', search);
                    console.log('Searching by key response', response);
                    return response;
                }),
            );
        }
    }, [search]);

    return (
        <SearchSowingsWrapper>
            <SearchInput type="text" value={search} label={t('search.label')} onChange={handleInputChange} />
            <ListWrapper>
                <ListSowings sowings={filteredSowings} onSelectionChanged={onSelectionChanged} selected={selected} />
            </ListWrapper>
        </SearchSowingsWrapper>
    );
};

export default SearchSowings;

const SearchSowingsWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin: 10px;
    flex-direction: column;
`;

const SearchInput = styled(Input)`
    margin-bottom: 15px;
    border: 1px solid black;
`;

const ListWrapper = styled.div`
    flex: 1 1 auto;
`;
