import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { SowingData } from 'types/Types';
import { getCropsNamesFromSowing } from '../../../../helpers/helper-functions';
import { useTranslation } from 'react-i18next';

export type ListItemSowingProps = {
    sowing: SowingData;
};

const ListItemSowing = ({ sowing }: ListItemSowingProps): ReactElement => {
    const { t } = useTranslation();
    return (
        <ListItem>
            <div>
                {sowing.date} - {t('commonText.nationalLocationId')}: {sowing.GERK} - {getCropsNamesFromSowing(sowing)}
            </div>
        </ListItem>
    );
};

export default ListItemSowing;

const ListItem = styled.div`
    padding: 1rem;
    align-self: center;
`;
