import Input from 'ui/molecules/Input/Input';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NewUser } from 'types/Types';
import { Title } from '../../../atoms/Title';
import { Button } from '../../../atoms/Button';
import { createNewUser } from '../../../../services/sled2-api';
import { MdAddCircle } from 'react-icons/md';
import DropdownSelect from '../../../atoms/DropdownSelect/DropdownSelect';
import { NEW_USER_TYPES_OPTIONS } from '../../../../config/custom-constants';
import { showToast } from '../../../atoms/Toast/Toast';
import { useTranslation } from 'react-i18next';

const initialNewUserState: NewUser = {
    name: '',
    vatNumber: '',
    address: '',
    post: '',
    country: '',
    email: '',
    password: '',
    userTypes: [],
};

const CreateNewUser = (): JSX.Element => {
    const { t } = useTranslation();
    const [newUser, setNewUser] = useState<NewUser>(initialNewUserState);
    const [userTypesString, setUserTypes] = useState<string>('');

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, name: e.target.value });
    };
    const handleVatNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, vatNumber: e.target.value });
    };
    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, address: e.target.value });
    };
    const handlePostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, post: e.target.value });
    };
    const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, country: e.target.value });
    };
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, email: e.target.value });
    };
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, password: e.target.value });
    };
    const handleUserTypeChange = (userType: string) => {
        if (newUser.userTypes.includes(userType)) {
            const newArray = newUser.userTypes.filter((element) => element !== userType);
            newUser.userTypes = newArray;
        } else {
            newUser.userTypes.push(userType);
        }
        let userTypes = '[';
        newUser.userTypes.forEach((s) => (userTypes = userTypes + s + ','));
        setUserTypes(userTypes + ']');
    };
    const addNewUser = () => {
        createNewUser(newUser)
            .then((res) => {
                console.log('Response', res);
                showToast({
                    position: 'top-right',
                    message: t('admin.createNewUser.successMessage'),
                    type: 'success',
                    BCtxHash: res.txLink,
                });
                setNewUser(initialNewUserState);
            })
            .catch((error) => {
                console.error('Error occured', error);
                showToast({
                    position: 'top-right',
                    message: t('admin.createNewUser.errorMessage'),
                    type: 'danger',
                    BCtxHash: error,
                });
            });
    };

    return (
        <CreateNewUserWrapper>
            <Title text={t('admin.createNewUser.title')} subtext=""></Title>
            <span style={{ margin: '10px 20px 10px 20px' }}>
                Polja označena z <span style={{ color: 'red' }}>*</span> so obvezna
            </span>
            <StyledInput
                label={t('admin.createNewUser.name')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newUser.name}
                onChange={handleNameChange}
                className={newUser.name != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewUser.vatNumber')}
                type="textarea"
                required={false}
                maxLength={100}
                value={newUser.vatNumber}
                onChange={handleVatNumberChange}
                //className={newUser.vatNumber != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewUser.address')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newUser.address}
                onChange={handleAddressChange}
                className={newUser.address != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewUser.post')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newUser.post}
                onChange={handlePostChange}
                className={newUser.post != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewUser.country')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newUser.country}
                onChange={handleCountryChange}
                className={newUser.country != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewUser.email')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newUser.email}
                onChange={handleEmailChange}
                className={newUser.email != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewUser.password')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newUser.password}
                onChange={handlePasswordChange}
                className={newUser.password != '' ? 'valid' : 'invalid'}
            />
            <DropdownSelect
                value={newUser.userTypes[0]}
                label={t('admin.createNewUser.userTypes')}
                isValid={true}
                isLoading={false}
                isDisabled={false}
                options={NEW_USER_TYPES_OPTIONS}
                onSelectedValue={(val) => handleUserTypeChange(val.toString())}
            />
            <span style={{ margin: '10px 20px 10px 20px' }}>UserTypes Selected {userTypesString}</span>
            <ContainerButton>
                <Button
                    onClick={addNewUser}
                    disabled={
                        !(
                            newUser.name != '' &&
                            newUser.address != '' &&
                            newUser.post != '' &&
                            newUser.country != '' &&
                            newUser.email != '' &&
                            newUser.password != '' &&
                            newUser.userTypes.length != 0
                        )
                    }
                    iconPosition="left"
                    icon={<MdAddCircle />}
                    text={t('admin.createNewUser.buttonAddNewPlus')}
                    btnType="primary"
                />
            </ContainerButton>
        </CreateNewUserWrapper>
    );
};

export default CreateNewUser;

const CreateNewUserWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
        margin-left: 33%;
        margin-right: 33%;
    }
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;

    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
`;

const ContainerButton = styled.div`
    width: 100%;
`;
