import React, { useState } from 'react';
import { Button } from 'ui/atoms/Button';
import { Input } from 'ui/molecules/Input';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

export type ChangePasswordAdminProps = {
    changePassword: (newPassword: string) => void;
    cancel: () => void;
};

const ChangePasswordAdmin = ({ changePassword, cancel }: ChangePasswordAdminProps): JSX.Element => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    };

    const handleSubmitClick = () => {
        if (error) return;

        changePassword(newPassword);
    };

    return (
        <div>
            <StyledInput
                name="newPassword"
                type="password"
                label={t('profile.newPassword')}
                value={newPassword}
                onChange={handleNewPasswordChange}
            />
            <ContainerBottom>
                <ContainerButton pos="left">
                    <Button text={t('navigation.cancel')} btnType="primary" onClick={cancel} />
                </ContainerButton>
                <ContainerButton pos="right">
                    <Button
                        disabled={error}
                        text={t('navigation.save')}
                        btnType="primary"
                        onClick={handleSubmitClick}
                    />
                </ContainerButton>
            </ContainerBottom>
            {error && <div>{errorMessage}</div>}
        </div>
    );
};

export default ChangePasswordAdmin;

const StyledInput = styled(Input)`
    margin-bottom: 24px;
`;

const ContainerBottom = styled.div`
    display: flex;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
