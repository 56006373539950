import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
    en: {
        translation: require('./locales/en/translation.json'),
    },
    sl: {
        translation: require('./locales/sl/translation.json'),
    },
    /*es: {
        translation: require('./locales/es/translation.json'),
    },*/
    // Add more languages as needed
};
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: navigator.language || 'en', // default language
        fallbackLng: 'sl', // fallback language if a translation is not available
        debug: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
