import { OptionsKOV, Wallet } from './Types';

export type UserType = 'PRIDELOVALEC' | 'PREVOZNIK' | 'KOV' | 'ADMIN';

export type IUser = {
    id: number;
    username: string;
    email: string;
    userType: UserType;
    ethWallet: Wallet;
    token: string;
    kov: string;
    name: string;
    sourceSystemId: string;
};

export type ILogin = {
    username: string;
    password: string;
    kov: OptionsKOV;
};

export const initialUserInfo: IUser = {
    id: 0,
    username: '',
    email: '',
    userType: 'PRIDELOVALEC',
    ethWallet: { address: '' },
    token: '',
    kov: '',
    name: '',
    sourceSystemId: '',
};
