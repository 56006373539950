import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { LoadedFiles, SowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import SearchSowings from 'ui/organisms/SearchSowings/SearchSowings';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Title } from 'ui/atoms/Title';
import { OverviewFinishedSowings } from 'ui/organisms/OverviewFinishedSowings';
import { getSowingBC } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

let initialStateSowingUpdateData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 0,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
    drawerIDs: [],
    activity_id: 0,
};

const FinishedSowingList: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [sowingDatas, setSowing] = useState<SowingData[]>([]);
    const [selected, setSelected] = useState<SowingData | null>(null);
    //let [selectedSowing, setSelectedSowing] = useState<SowingData>();
    const [sowingData, setSowingUpdateData] = useState<SowingData>(initialStateSowingUpdateData);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [files, setLoadedFiles] = useState<LoadedFiles[]>([]);
    const [txLink, setTxLink] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    //let selectedSowing: SowingData | null = null;

    const setSelectedSowingFromParcel = () => {
        console.log('TEst get sowing from ID:', selected);
        if (selected != null) {
            initialStateSowingUpdateData = selected;
        }
        console.log('SELECTED SOWING DATA:', initialStateSowingUpdateData);
    };

    const getSowing = useCallback(async () => {
        setLoading(true);

        getSowingBC(2)
            .then((res) => {
                setSowing(res);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, [sowingDatas]);

    useEffect(() => {
        getSowing();
    }, []);

    useEffect(() => {
        setSelectedSowingFromParcel();
        setSowingUpdateData({ ...initialStateSowingUpdateData });
        console.log('Sowing data UPDATED: ', sowingData);
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('finishedSowing.title')}
                                                subtext={t('finishedSowing.searchSowingSubtitle')}
                                            />
                                            <SearchSowings
                                                onSelectionChanged={setSelected}
                                                sowings={sowingDatas}
                                                selected={selected}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('finishedSowing.title')}
                                                subtext={t('finishedSowing.history')}
                                            />
                                            <OverviewFinishedSowings
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={true}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            onClick={stepDec}
                                            disabled={step === 0}
                                            iconPosition="left"
                                            icon={<GoArrowLeft />}
                                            text={t('navigation.back')}
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                    {step !== 1 && (
                                        <>
                                            <ContainerButton pos="right">
                                                <Button
                                                    onClick={stepInc}
                                                    disabled={selected === null || step === 1}
                                                    iconPosition="right"
                                                    icon={<GoArrowRight />}
                                                    text={t('navigation.next')}
                                                    btnType="primary"
                                                />
                                            </ContainerButton>
                                        </>
                                    )}
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage
                                    message={t('finishedSowing.updateSowingSuccessMessage')}
                                    txLink={txLink}
                                />
                                <Button
                                    text={t('navigation.moveToAHome')}
                                    onClick={() => history.push('/')}
                                    btnType="primary"
                                />
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default FinishedSowingList;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
