import { Button } from 'ui/atoms/Button';
import React, { ReactElement } from 'react';
import { ConfirmHarvestRequest, ConfirmHarvestRequestSled2, HarvestData } from 'types/Types';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { showToast } from 'ui/atoms/Toast/Toast';
import { useHistory } from 'react-router-dom';
import { addVc1Images, confirmHarvest, confirmHarvestNew } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

type ConfirmHarvestProps = {
    harvestData: HarvestData[];
    ipfsPaths: string[];
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLinks: React.Dispatch<React.SetStateAction<string[]>>;
};

const ConfirmHarvest = ({
    harvestData,
    ipfsPaths,
    setSuccess,
    setLoading,
    setError,
    setTxLinks,
}: ConfirmHarvestProps): ReactElement => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const history = useHistory();

    const confirmHarvestHandler = async () => {
        // TODO: Validate data before sending
        history.push('/home');

        //setLoading(true);
        const txLinkArray: string[] = [];
        for (const item of harvestData) {
            // map initial confirm harvest request object
            const confirmHarvestReq: ConfirmHarvestRequest = {
                _id: item.crop!._id,
                recipient: null,
                description: item.description,
                quantity: item.quantity,
                unit: item.crop!.enota,
                date: item.date,
                images_ipfs: [],
                proof: item.proof,
            };
            const form = new FormData();
            if (item.activity_id != undefined) {
                try {
                    // append request
                    form.append('harvest_request', JSON.stringify(confirmHarvestReq));
                    // if images exists append them to form
                    if (item.loadedFiles != undefined && item.loadedFiles.length > 0) {
                        for (const image of item.loadedFiles) {
                            form.append('images_ipfs', image.data, image.name);
                        }
                    }
                    confirmHarvestNew(item.activity_id, form)
                        .then((res) => {
                            console.log('success');
                        })
                        .catch((error) => {
                            console.log('error');
                        });
                } catch (error) {
                    console.log('Error occured in confirming new harvest. ERROR:', error);
                    showToast({
                        position: 'top-right',
                        message: t('harvest.confirm.toastErrorMessage'),
                        type: 'danger',
                    });
                }
            }
        }
        showToast({
            position: 'top-right',
            message: t('harvest.confirm.toastSuccessMessage'),
            type: 'success',
            BCtxHash: txLinkArray[0],
        });
        console.log('Transaction link array: ', txLinkArray);
        //setTxLinks(txLinkArray);
        //setLoading(false);
        //setSuccess(true);
    };

    return (
        <ConfirmHarvestWrapper key={'rwa'}>
            {harvestData.map((harvestDataItem) => (
                <div key={harvestDataItem.proof}>
                    <div>
                        <div>
                            <strong>{t('harvest.confirm.cropName')}</strong> {harvestDataItem.crop?.name}
                        </div>
                        <div>
                            <strong>{t('harvest.confirm.description')}</strong> {harvestDataItem.description}
                        </div>
                        <div>
                            <strong>{t('harvest.confirm.quantity')}</strong> {harvestDataItem.quantity}
                            {harvestDataItem.crop?.enota}
                        </div>
                        <div>
                            <strong>{t('harvest.confirm.date')}</strong> {harvestDataItem.date}
                        </div>
                        <div>
                            {harvestDataItem.loadedFiles?.map((files) => (
                                <div key={files.preview}>
                                    <strong>{t('harvest.confirm.images')}</strong>
                                    <Image>
                                        <img src={files.preview} alt="" key={files.preview} />
                                    </Image>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            <BottomWrapper>
                <Button onClick={confirmHarvestHandler} text={t('navigation.confirm')} btnType="primary-outline" />
            </BottomWrapper>
        </ConfirmHarvestWrapper>
    );
};

export default ConfirmHarvest;

const ConfirmHarvestWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const Image = styled.div`
    display: flex;
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
`;
