import { API_ADDRESS_SLED2 } from 'config/config';
import axios from 'axios';
import {
    ActivityHistory,
    AdminUpdateUserPasswordRequest,
    ConfirmHarvestRequest,
    ConfirmHarvestResponse,
    CreateUserRequest,
    CreateUserResponse,
    Crop,
    DataVC1,
    DataVC2,
    DataVC3,
    FetchedVCData,
    FetchedVCDataMulti,
    FetchedVCDataMultiNew,
    GetImagesResponse,
    ImageInfoRequestData,
    ImageInfoResponseData,
    Komercialist,
    KOV,
    LoadedFiles,
    NewChain,
    NewItem,
    NewUser,
    OptionsKOV,
    Sled2Partners,
    SowingCreateDataResponse,
    SowingData,
    UpdateSowingData,
    UpdateSowingResponse,
    UpdateUserPasswordRequest,
    User,
    VASCOprevzemnicaResponse,
} from 'types/Types';
import { IUser } from '../types/user-types';

export const instanceAxios = axios.create({
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': API_ADDRESS_SLED2,
        'Access-Control-Allow-Credentials': true,
    },
});

export const createNewChain = async (newChainData: NewChain) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/user/admin/create/chain`, newChainData)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => reject(err));
    });
export const createNewUser = async (newUserData: NewUser) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/user/admin/create/user`, newUserData)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => reject(err));
    });

export const createNewItem = async (newItemData: NewItem) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/user/admin/create/item`, newItemData)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => reject(err));
    });

export const getAllKOV = async () =>
    //setLoading(true);
    new Promise<OptionsKOV[]>((resolve, reject) => {
        console.log(`test url : ${API_ADDRESS_SLED2}`, API_ADDRESS_SLED2);
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/kov`)
            .then((res) => {
                console.log('TEST Function: getallKOV');
                const optionsKOV: OptionsKOV[] = [];
                res.data.forEach((kov: KOV) => {
                    const option: OptionsKOV = {
                        value: kov.id,
                        label: kov.name,
                    };
                    optionsKOV.push(option);
                });
                resolve(optionsKOV);
            })
            .catch((err) => reject(err));
    });

export const userLogin = async (usernameIn: string, passwordIn: string, kovIn: OptionsKOV) =>
    //setLoading(true);
    new Promise<IUser>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/login`, { username: usernameIn, password: passwordIn, kov: kovIn.value })
            .then((res) => {
                console.log('test login', res);
                const user: IUser = {
                    id: res.data.id,
                    email: res.data.email,
                    username: res.data.username,
                    kov: kovIn.label,
                    userType: res.data.user_type[0], // TODO: change this to take array
                    ethWallet: res.data.eth_wallet.address,
                    token: res.data.token,
                    name: res.data.name,
                    sourceSystemId: res.data.source_system_id,
                };
                console.log('test login 12 user', user);
                resolve(user);
            })
            .catch((err) => reject(err));
    });

export const getUserItems = async (userId: number) =>
    new Promise<Crop[]>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS_SLED2}/api/user/${userId}/items`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((res) => {
                console.log('GetCrops', res);
                const userCropCodes: Crop[] = [];
                for (const item of res.data) {
                    const itemCrop: Crop = {
                        _id: item.source_system_item_id,
                        name: item.name,
                        enota: item.unit,
                        prodajnaCena: item.price,
                        prodajnaCena1DDV: item.price_with_tax,
                    };
                    userCropCodes.push(itemCrop);
                }
                resolve(userCropCodes);
            })
            .catch((err) => reject(err));
    });

export const getItems = async (sourceSystemId: number) =>
    new Promise<Crop[]>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS_SLED2}/api/items/${sourceSystemId}/list`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((res) => {
                console.log('GetCrops', res);
                const userCropCodes: Crop[] = [];
                for (const item of res.data) {
                    const itemCrop: Crop = {
                        _id: item.source_system_item_id,
                        name: item.name,
                        enota: item.unit,
                        prodajnaCena: item.price,
                        prodajnaCena1DDV: item.price_with_tax,
                    };
                    userCropCodes.push(itemCrop);
                }
                resolve(userCropCodes);
            })
            .catch((err) => reject(err));
    });

export const updateSowingSled2New = async (data: UpdateSowingData) =>
    new Promise<UpdateSowingResponse>((resolve, reject) => {
        instanceAxios
            .put(`${API_ADDRESS_SLED2}/api/new/activity/${data.activity_id}/sowing`, { data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const updateSowingSled2 = async (data: UpdateSowingData) =>
    new Promise<UpdateSowingResponse>((resolve, reject) => {
        instanceAxios
            .put(`${API_ADDRESS_SLED2}/api/activity/sowing`, { data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const getSowingBC = async (sowingStatus?: number) =>
    new Promise<SowingData[]>((resolve, reject) => {
        const sowingStatusParam = sowingStatus != null ? `?sowingStatus=${sowingStatus}` : '';
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/new/activity/sowings${sowingStatusParam}`)
            .then((res) => {
                console.log('GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    sowingObject.drawerIDs = data.dataIds;
                    sowingObject.activity_id = data.activity_id;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }

                sowingArray?.sort((a, b) => b.date.localeCompare(a.date));
                console.log('Response from GET sowing', sowingArray);
                resolve(sowingArray);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });

export const confirmSowingNew = async (data: DataVC1, files: LoadedFiles[]) =>
    new Promise<FetchedVCData>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/new/activity/sowing/create`, { data: data })
            .then((res) => {
                const response: FetchedVCData = res.data;
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

export const getPartners = async () =>
    new Promise<Sled2Partners[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/user/partnerji?sourceSystemId=1`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const getRecipients = async () =>
    new Promise<User[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/recipients`)
            .then((res) => {
                const response: User[] = res.data;
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

export const getVCDataSled2 = async () =>
    new Promise<FetchedVCData[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/new/activity/vc/fetch/vc1`)
            .then((res) => {
                const response: FetchedVCData[] = res.data;
                response?.sort((a, b) => b.data.subject.date.localeCompare(a.data.subject.date));
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });

export const sowingCreateDataSled2 = async (activityID: number, files: LoadedFiles[], ipfsPathIn?: string) =>
    new Promise<SowingCreateDataResponse>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity/sowing/createData`, {
                activity_id: activityID,
                data: { ipfsPath: ipfsPathIn },
            })
            .then((res) => {
                console.debug('Sowing create data response: ', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.error('sowing create data error response', err);
                reject(err);
            });
    });

export const addImages = async (form: FormData) =>
    new Promise((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity/images`, form, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((res) => {
                console.debug('Image added to drawer ID: ', res);
                resolve(res);
            })
            .catch((err) => {
                console.error('Image added to drawer ID error response', err);
                reject(err);
            });
    });
export const addImagesInfos = async (imageInfoRequestData: ImageInfoRequestData) =>
    new Promise((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity//imageInfo`, { data: imageInfoRequestData })
            .then((res) => {
                console.debug('Image added to drawer ID: ', res);
                resolve(res);
            })
            .catch((err) => {
                console.error('Image added to drawer ID error response', err);
                reject(err);
            });
    });
export const addImagesInfosNew = async (activityID: number, imageInfoRequestData: ImageInfoRequestData) =>
    new Promise<ImageInfoResponseData>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity/${activityID}/imageInfo/new`, { data: imageInfoRequestData })
            .then((res) => {
                console.debug('Image added to drawer ID: ', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.error('Image added to drawer ID error response', err);
                reject(err);
            });
    });

export const addImagesNew = async (activityID: number, imageInfoId: number, form: FormData) =>
    new Promise((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity/${activityID}/imageinfo/${imageInfoId}/images/new`, form, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((res) => {
                console.debug('Image added to drawer ID: ', res);
                resolve(res);
            })
            .catch((err) => {
                console.error('Image added to drawer ID error response', err);
                reject(err);
            });
    });

export const getImages = async (drawerId: string) =>
    new Promise<GetImagesResponse>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/activity/images/${drawerId}`)
            .then((res) => {
                console.debug('Get images from drawer: ', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.error('Get images from drawer error:', err);
                reject(err);
            });
    });

export const getImagesInfo = async (drawerId: string) =>
    new Promise((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/activity/imageInfo/${drawerId}`)
            .then((res) => {
                console.debug('Image info from drawer: ', res);
                resolve(res.data.imageInfo);
            })
            .catch((err) => {
                console.error('Getting image info from Drawer error response', err);
                reject(err);
            });
    });

export const addVc1Images = async (form: FormData) =>
    new Promise<GetImagesResponse>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity/vc/images`, form, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((res) => {
                console.debug('Image added to drawer ID: ', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.error('Image added to drawer ID error response', err);
                reject(err);
            });
    });

export const confirmHarvestNew = async (previousActivityId: number, form: FormData) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/new/activity/${previousActivityId}/vc/create/vc1`, form, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((res) => {
                console.debug('Image added to drawer ID: ', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.error('Image added to drawer ID error response', err);
                reject(err);
            });
    });

export const confirmHarvest = async (confirmHarvestReq: ConfirmHarvestRequest) =>
    new Promise<ConfirmHarvestResponse>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/activity/vc/create/vc1/Old`, { data: confirmHarvestReq })
            .then((res) => {
                resolve(res.data.txLink);
            })
            .catch((err) => reject(err));
    });

export const createUserSled2 = async (createUserRequest: CreateUserRequest) =>
    new Promise<CreateUserResponse>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/user/create`, createUserRequest)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const getUserProfile = async () =>
    new Promise<IUser>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/user/profile`)
            .then((res) => {
                const user: IUser = {
                    id: res.data.id,
                    email: res.data.email,
                    username: res.data.username,
                    kov: '',
                    userType: res.data.user_type[0], // TODO: change this to take array
                    ethWallet: res.data.eth_wallet.address,
                    token: res.data.token,
                    name: res.data.name,
                    sourceSystemId: res.data.source_system_id,
                };
                resolve(user);
            })
            .catch((err) => reject(err));
    });

export const adminUpdateUserPassword = async (request: AdminUpdateUserPasswordRequest) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/user/admin/update/password`, request)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const updateUserPassword = async (request: UpdateUserPasswordRequest) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/user/update/password`, request)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const getRegisteredUsersOld = async () =>
    new Promise<User[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/user/registered/Old`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const getRegisteredUsers = async () =>
    new Promise<User[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/user/registered`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const confirmPickupCreationVC2 = async (data: DataVC2) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/new/activity/vc/create/vc2`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const getVC2 = async () =>
    new Promise<FetchedVCDataMulti[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/new/activity/vc/fetch/vc2`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const getVcDataByScaningQrCode = async (scanResult: string) =>
    new Promise<FetchedVCDataMultiNew>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/new/activity/vc/ipfsAddress/${scanResult}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const confirmPickupVC3 = async (data: DataVC3) =>
    new Promise<any>((resolve, reject) => {
        instanceAxios
            .post(`${API_ADDRESS_SLED2}/api/new/activity/vc/create/vc3`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const getVC3 = async () =>
    new Promise<FetchedVCDataMulti[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/new/activity/vc/fetch/vc3`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const getActivityHistory = async (activity_id: number) =>
    new Promise<ActivityHistory[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/new/activity/history/${activity_id}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const getComercialists = async () =>
    new Promise<Komercialist[]>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/komercialisti`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

export const getVascoPrevzemnica = async (stevilkaPrevzemnice: string) =>
    new Promise<VASCOprevzemnicaResponse>((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/vasco/FA/prevzemnica/${stevilkaPrevzemnice}`)
            .then((res) => {
                resolve(res.data[0]);
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });

/**
 * hajsdkhf
 * sadf
 * asdf
 * afd
 * @param ipfsPath
 */
export const getSowingByIpfS = async (ipfsPath: string) =>
    new Promise((resolve, reject) => {
        instanceAxios
            .get(`${API_ADDRESS_SLED2}/api/activity/sowing/${ipfsPath}`)
            .then((res) => {
                console.log('GET sowingIPFS RESPONSE: ', res);
            })
            .catch((err) => {
                console.error(err);
            });
    });

/**
 * Old functionalities
 * TODO remove when refactoring is finished
 */
/*export const getVCDataByIpfsAddress = async (ipfsAddressHash: string, token: string) =>
    new Promise<FetchedVCDataMultiNew | null>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS_SLED2}/vc/ipfsAddress/${ipfsAddressHash}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export const getUserActivityLogs = async (token: string, params: any) =>
    new Promise<any>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS_SLED2}/logs/`, {
                headers: { Authorization: `Bearer ${token}` },
                params: params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });

export class BlockISAPIService {
    public async getSowingAsync(userToken: string): Promise<SowingData[] | any> {
        axios
            .get(`${API_ADDRESS_SLED2}/sowing`, { headers: { Authorization: `Bearer ${userToken}` } })
            .then((res) => {
                console.log('[BlockISAPIService] : GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('[BlockISAPIService] : Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }
                console.log('[BlockISAPIService] : Response from GET sowing', sowingArray);
                return sowingArray;
                //setSowing(sowingArray);
            })
            .catch((err) => {
                console.error(err);
                throw err;
            })
            .finally(() => {
                console.info('[BlockISAPIService] Finish get sowings');
            });
    }
}*/
