import React, { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import Select from 'react-select';
import styled from 'styled-components';
import { AdminUpdateUserPasswordRequest, User } from 'types/Types';
import { ChangePasswordAdmin } from '../ChangePasswordAdmin';
import { useApiState } from 'hooks/useApiState';
import { adminUpdateUserPassword } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

export type EditUserInfoProps = {
    user: User;
};

const EditUserInfo = ({ user }: EditUserInfoProps): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [newUser, setNewUser] = useState<any>(null);
    const [currentAction, setCurrentAction] = useState(0);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const cancel = () => {
        setCurrentAction(0);
    };

    const changePassword = (newPassword: string) => {
        const data: AdminUpdateUserPasswordRequest = {
            userId: user._id,
            newPassword: newPassword,
        };

        adminUpdateUserPassword(data)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                setCurrentAction(0);
            });
    };

    return (
        <Wrapper>
            {currentAction === 0 && (
                <div>
                    <div>
                        {t('admin.editUser.username')} {user.username}
                    </div>
                    <div>
                        {t('admin.editUser.email')} {user.email}
                    </div>
                    <div>
                        {t('admin.editUser.userType')} {user.userType}
                    </div>
                    {user.ethWallet && (
                        <div>
                            {t('admin.editUser.EthAddress')} {user.ethWallet.address}
                        </div>
                    )}
                    <Button
                        text={t('admin.editUser.buttonText')}
                        btnType="primary"
                        onClick={() => setCurrentAction(1)}
                    />
                </div>
            )}
            {currentAction === 1 && <ChangePasswordAdmin cancel={cancel} changePassword={changePassword} />}
        </Wrapper>
    );
};

export default EditUserInfo;

const Wrapper = styled.div`
    margin: 10px;
    flex: 1 1 auto;
`;
