import { DropdownSelectableOptions } from 'types/Types';

/**
 * DROPDOWNS SELECT OPTIONS
 * The following constants are used for Dropdowns.
 * They describes which options will be possible in which select
 */
export const DROPDOWN_PLACEHOLDER_TEXT = 'Izberi...';

export const SOWING_CREATE_COMMENT_OPTIONS: DropdownSelectableOptions[] = [
    { label: 'Sajenje novega pridelka', value: 'Sajenje novega pridelka' },
    { label: 'Trajni nasad', value: 'Trajni nasad' },
    { label: 'Zaporedna pridelava', value: 'Terminska pridelava' },
    { label: 'Predelava', value: 'Predelava' },
];

export const SOWING_UPDATE_DESCRIPTION_OPTIONS: DropdownSelectableOptions[] = [
    { label: 'Rast', value: 'Rast' },
    { label: 'Sajenje/setev', value: 'Sajenje/setev' },
    { label: 'Namakanje', value: 'Namakanje' },
    { label: 'Predelava', value: 'Predelava' },
];

export const HARVEST_CREATE_DESCRIPTION_OPTIONS: DropdownSelectableOptions[] = [
    { label: 'Spravilo pridelka', value: 'Spravilo pridelka' },
    { label: 'Pakiranje proizvoda', value: 'Pakiranje proizvoda' },
];

const ACTIVITY_MAP_TEMP = new Map<string, string>();
ACTIVITY_MAP_TEMP.set('SOWING_CREATED', 'Kreiranje novega pridelka');
ACTIVITY_MAP_TEMP.set('SOWING_STATUS_CHANGED', 'Sprememba statusa pridelka');
ACTIVITY_MAP_TEMP.set('BUCKET_CREATED', 'Kreiranje predala za slike');
ACTIVITY_MAP_TEMP.set('VC_1_CREATED', 'Kreiranje spravila');
ACTIVITY_MAP_TEMP.set('VC_2_CREATED', 'Kreiranje dobavnice');
ACTIVITY_MAP_TEMP.set('VC_3_CREATED', 'Kreiranje prevzemnice');
ACTIVITY_MAP_TEMP.set('VC_REJECTED', 'Zavrnjen prevzem');
ACTIVITY_MAP_TEMP.set('IMAGES_ADDED', 'Dodane slike');
ACTIVITY_MAP_TEMP.set('IMAGES_DELETED', 'Brisanje slik');
ACTIVITY_MAP_TEMP.set('IMAGE_INFO_ADDED', 'Dodane dodatne informacije o slikah');
ACTIVITY_MAP_TEMP.set('IMAGE_INFO_CHANGED', 'Spremenjene dodatne informacije o slikah');
ACTIVITY_MAP_TEMP.set('IMAGE_INFO_DELETED', 'Brisanje dodatnih informacij o slikah');

export const ACTIVITY_MAP = ACTIVITY_MAP_TEMP;

export const NEW_USER_TYPES_OPTIONS: DropdownSelectableOptions[] = [
    { label: 'PRIDELOVALEC', value: 'PRIDELOVALEC' },
    { label: 'PREDELOVALEC', value: 'PREDELOVALEC' },
    { label: 'PREVOZNIK', value: 'PREVOZNIK' },
    { label: 'KOMERCIALIST', value: 'KOMERCIALIST' },
];
