import React from 'react';
import { useAuth, UserType } from 'contexts/AuthContext';
import styled from 'styled-components';
import { LinkDescription } from 'types/Types';
import { SidebarNavLinks } from 'ui/organisms/SidebarNavLinks';
import { FaNetworkWired, FaPlusCircle, FaUserPlus, FaUsersCog } from 'react-icons/fa';
import { CgList, CgPlayListCheck } from 'react-icons/cg';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { BiScan } from 'react-icons/bi';
import { GiFarmer, GiGrass } from 'react-icons/gi';
import { Button } from 'ui/atoms/Button';
import { useTranslation } from 'react-i18next';

export type SidebarProps = {
    toggleSidebar: () => void;
};

const Sidebar = ({ toggleSidebar }: SidebarProps): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();

    const headerSowing = { tag: t('sidebar.headers.sowing'), path: null };
    const headerHarvest = { tag: t('sidebar.headers.harvest'), path: null };
    const headerSupply = { tag: t('sidebar.headers.supply'), path: null };
    const headerPickup = { tag: t('sidebar.headers.pickup'), path: null };
    const headerAdmin = { tag: t('sidebar.headers.admin'), path: null };

    const linkHarvest = { tag: t('sidebar.link.harvest'), path: 'harvest', icon: <GiFarmer /> };
    const linkSowing = { tag: t('sidebar.link.sowing'), path: 'sowing', icon: <GiGrass /> };
    const linkSowingUpdate = { tag: t('sidebar.link.sowingUpdate'), path: 'sowingupdate', icon: <GiGrass /> };
    const linkSowingFinished = { tag: t('sidebar.link.sowingFinished'), path: 'sowingfinished', icon: <GiGrass /> };
    const linkSupplyCreate = { tag: t('sidebar.link.supplyCreate'), path: 'pickup/create', icon: <AiOutlineFileAdd /> };
    const linkSupplyCreateMulti = {
        tag: t('sidebar.link.supplyCreateMulti'),
        path: 'pickup/createMulti',
        icon: <AiOutlineFileAdd />,
    };
    const linkSupplyGet = { tag: t('sidebar.link.supplyGet'), path: 'pickup/get', icon: <CgList /> };
    const linkSupplyEnd = { tag: t('sidebar.link.supplyEnd'), path: 'pickup/overview', icon: <CgList /> };
    const linkPickupScan = { tag: t('sidebar.link.pickupScan'), path: 'pickup/scan', icon: <BiScan /> };
    const linkPickupOverview = {
        tag: t('sidebar.link.pickupOverview'),
        path: 'pickup/overview',
        icon: <CgPlayListCheck />,
    };
    const linkUserDashboardAdd = {
        tag: t('sidebar.link.userDashboardAdd'),
        path: 'admin/user_dashboard_add',
        icon: <FaUserPlus />,
    };
    const linkUserDashnoardManage = {
        tag: t('sidebar.link.userDashboardManage'),
        path: 'admin/user_dashboard_manage',
        icon: <FaUsersCog />,
    };
    const linkUserDashboardActivityLogs = {
        tag: t('sidebar.link.userDashboardActivityLogs'),
        path: 'admin/user_dashboard_activitylog',
        icon: <FaUsersCog />,
    };

    const linkAddNewSourceSystem = {
        tag: t('sidebar.link.addNewSourceSystem'),
        path: 'admin/create_new_chain',
        icon: <FaNetworkWired />,
    };

    const linkAddNewUser = {
        tag: t('sidebar.link.addNewUser'),
        path: 'admin/create_new_user',
        icon: <FaUserPlus />,
    };

    const linkAddNewItem = {
        tag: t('sidebar.link.addNewItem'),
        path: 'admin/create_new_item',
        icon: <FaPlusCircle />,
    };

    const mapUserTypeToLinks = new Map<UserType, LinkDescription[]>([
        [
            'PRIDELOVALEC',
            [
                headerSowing,
                linkSowing,
                linkSowingUpdate,
                linkSowingFinished,
                headerHarvest,
                linkHarvest,
                headerSupply,
                linkSupplyCreate,
                linkSupplyGet,
                linkSupplyEnd,
                // headerPickup,
                // linkPickupOverview,
            ],
        ],
        [
            'PREVOZNIK',
            [headerSupply, linkSupplyCreateMulti, linkSupplyGet, headerPickup, linkPickupScan, linkPickupOverview],
        ],
        ['KOV', [headerPickup, linkPickupScan, linkPickupOverview]],
        [
            'ADMIN',
            [
                headerAdmin,
                linkUserDashboardAdd,
                linkUserDashnoardManage,
                linkUserDashboardActivityLogs,
                linkAddNewSourceSystem,
                linkAddNewUser,
                linkAddNewItem,
            ],
        ],
    ]);

    function getLinks(): LinkDescription[] {
        const links = mapUserTypeToLinks.get(currentUser.userType);
        return links ? links : [];
    }

    return (
        <SidebarWrapper>
            <SidebarLogoContainer>
                <SidebarLogo src={process.env.PUBLIC_URL + '/ztocka.jpg'} />
            </SidebarLogoContainer>
            <Ul>
                <SidebarNavLinks links={getLinks()} />
            </Ul>
            <StyledButton text={t('sidebar.close')} btnType="secondary" onClick={toggleSidebar} />
        </SidebarWrapper>
    );
};

export default Sidebar;

const SidebarWrapper = styled.div`
    background: var(--color);
    position: relative;
    display: flex;
    flex: 0 0 256px;
    flex-direction: column;
    width: 256px;
    padding: 0;
    box-shadow: none;
    height: 100%;
`;

const Ul = styled.ul`
    position: relative;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    flex: 1 1;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;

    margin-top: 0;
    overflow: hidden;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
`;

const SidebarLogoContainer = styled.div`
    color: #fff;
    background: rgba(0, 0, 21, 0.2);
    display: flex;
    flex: 0 0 56px;
    align-items: center;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    border-radius: 0;

    @media screen and (min-width: 768px) {
        display: none;
        margin-top: auto;
    }
`;

const SidebarLogo = styled.img`
    width: 100%;
    margin-right: 1px;
    border-right: 1px solid #3c4b64;
`;
