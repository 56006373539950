import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from '../LoadingAnimationWrapper';
import { LoadedFiles } from 'types/Types';
import Resizer from 'react-image-file-resizer';

export type FileDropzoneProps = {
    parentFiles?: LoadedFiles[];
    setIpfsPaths: React.Dispatch<React.SetStateAction<string[]>>;
    setLoadedFiles: React.Dispatch<React.SetStateAction<LoadedFiles[]>>;
};

const FileDropzone2 = ({ parentFiles, setIpfsPaths, setLoadedFiles }: FileDropzoneProps): JSX.Element => {
    const [files, setFiles] = useState<LoadedFiles[]>([]);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 3,
        onDrop: async (acceptedFiles: any) => {
            await Promise.all(
                acceptedFiles.map((image: any) => {
                    console.log(image);
                    return resizeFile(image);
                }),
            ).then((uploadBranchImages) => {
                console.log('Upload Branch Images', uploadBranchImages);
                setFiles(
                    uploadBranchImages.map((image: any) => {
                        return { name: image.name, preview: URL.createObjectURL(image.blob), data: image.blob };
                    }),
                );
            });
        },
    });

    const resizeFile = (file: any) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1280,
                720,
                'JPEG',
                80,
                0,
                (uri) => {
                    const data = {
                        name: file.name,
                        blob: uri,
                    };
                    resolve(data);
                },
                'blob',
            );
        });

    function settingLoadedFiles() {
        console.log('Images to add', files);
        setLoadedFiles(files);
        setSuccess(true);
    }

    useEffect(() => {
        if (!success && files.length > 0) {
            settingLoadedFiles();
        }
    }, [files]);

    useEffect(() => {
        if (parentFiles != undefined) {
            if (parentFiles.length === 0) {
                setSuccess(false);
            } else {
                setSuccess(true);
            }
            setFiles(parentFiles);
        }
    }, [parentFiles]);

    return (
        <FileDropzoneWrapper>
            <LoadingAnimationWrapper loading={loading}>
                {!success && files.length === 0 && (
                    <FileDropzoneArea {...getRootProps()}>
                        <input {...getInputProps()} disabled={files.length !== 0} />
                        <Disclaimer>
                            Dodaj slike
                            {<br />}
                            <strong>(Max 3 slike)</strong>
                        </Disclaimer>
                    </FileDropzoneArea>
                )}
                {success && files.length > 0 && (
                    <>
                        <ImagePreviewList>
                            {files.map((file) => (
                                <li key={file.name}>
                                    <h2>{file.name}</h2>
                                    <img width="200px" height="200px" src={file.preview} key={file.preview} />
                                </li>
                            ))}
                        </ImagePreviewList>
                    </>
                )}
            </LoadingAnimationWrapper>
        </FileDropzoneWrapper>
    );
};

export default FileDropzone2;

const FileDropzoneWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;
const FileDropzoneArea = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #d8dbe0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-transform: none;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    & > p {
        touch-action: none;
        user-select: none;
    }
`;

const ImagePreviewList = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;
const Disclaimer = styled.p`
    justify-content: center;
`;
