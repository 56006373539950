import React, { CSSProperties } from 'react';
import { ExpandableItem } from 'ui/molecules/ExpandableItem';
import { SelectableItem } from 'ui/atoms/SelectableItem';
import { Sled2Partners } from '../../../../types/Types';

export type SelectableListItemPartnerProps = {
    partner: Sled2Partners;
    onSelectionChanged: React.Dispatch<React.SetStateAction<string | null>>;
    selected: string | null; // TODO: Change to boolean ?
    style?: CSSProperties;
};

const SelectableListItemPartner = ({
    partner,
    onSelectionChanged,
    selected,
    style,
}: SelectableListItemPartnerProps): JSX.Element => {
    return (
        <ExpandableItem
            header={
                <SelectableItem
                    onClick={() => onSelectionChanged(partner.source_system_user_id)}
                    selected={partner.source_system_user_id == selected}
                >
                    {partner.name}
                </SelectableItem>
            }
            itemContent={
                <div>
                    <div>{`Davčna: ${partner.taxNumber}`}</div>
                    <div>{`Email: ${partner.email}`}</div>
                </div>
            }
            style={style}
        />
    );
};

export default SelectableListItemPartner;
