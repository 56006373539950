import React from 'react';
import styled from 'styled-components';
import { SelectableListItemPartner } from 'ui/molecules/SelectableListItem/Partner';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Sled2Partners } from '../../../../types/Types';

export type ListPartnersProps = {
    partners: Sled2Partners[]; // Change type to partner ? Type is defined in types/types.ts
    selected: number | null;
    onSelectionChanged: React.Dispatch<React.SetStateAction<number | null>>;
};

const ItemRenderer = ({ style, index, data }: ListChildComponentProps) => {
    const item = data.partners[index];

    return (
        <SelectableListItemPartner
            key={index}
            partner={item}
            selected={data.selected}
            onSelectionChanged={data.onSelectionChanged}
            style={style}
        />
    );
};

const ListPartners = ({ partners, selected, onSelectionChanged }: ListPartnersProps): JSX.Element => {
    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    itemData={{ partners: partners, selected: selected, onSelectionChanged: onSelectionChanged }}
                    itemSize={60}
                    height={height}
                    width={width}
                    itemCount={partners.length}
                >
                    {ItemRenderer}
                </List>
            )}
        </AutoSizer>
    );
};

export default ListPartners;

const List = styled(FixedSizeList)`
    user-select: none;
    flex: 1 1 auto;
`;
