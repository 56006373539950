import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { User } from 'types/Types';
import { useTranslation } from 'react-i18next';

export type ListItemUserProps = {
    user: User;
};

const ListItemUser = ({ user }: ListItemUserProps): ReactElement => {
    const { t } = useTranslation();
    return (
        <ListItem>
            <div>
                {t('pickup.confirm.itemName')}
                {user.name}
            </div>
            <div>
                {t('pickup.confirm.name')}
                {user.username}
            </div>
            <div>
                {t('pickup.confirm.companyType')}
                {user.userType}
            </div>
            <div>
                {t('pickup.confirm.email')}
                {user.email}
            </div>
        </ListItem>
    );
};

const ListItem = styled.div`
    padding: 1rem;
    align-self: center;
`;

export default ListItemUser;
