import React, { useContext, useEffect, useState } from 'react';
import { useApiState } from 'hooks/useApiState';
import { getUserProfile, userLogin } from '../services/sled2-api';
import { ILogin, initialUserInfo, IUser } from '../types/user-types';

export type UserType = 'PRIDELOVALEC' | 'PREVOZNIK' | 'KOV' | 'ADMIN';

type AuthProviderProps = {
    children?: React.ReactNode;
};

export type IAuthContext = {
    isAuthenticated: boolean | null;
    currentUser: IUser;
    loading: boolean;
    error: string;
    login: (data: ILogin) => void;
    logout: () => void;
};

const AuthContext = React.createContext<IAuthContext>({
    isAuthenticated: false,
    currentUser: initialUserInfo,
    loading: true,
    error: '',
    login: () => {
        return;
    },
    logout: () => {
        return;
    },
});

export function useAuth(): IAuthContext {
    return useContext(AuthContext);
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const { error, loading, setLoading, setError } = useApiState();
    const [currentUser, setCurrentUser] = useState<IUser>(initialUserInfo);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(false);

    function login({ kov, ...data }: ILogin): void {
        setLoading(true);
        userLogin(data.username, data.password, kov)
            .then((user: IUser) => {
                //try {
                console.log('UserLogin Success', user);
                localStorage.setItem('token', user.token);
                localStorage.setItem('kov', kov.label);
                localStorage.setItem('vascoUserID', user.sourceSystemId);
                localStorage.setItem('name', user.name);
                setCurrentUser(user);
                setIsAuthenticated(true);
                console.log('UserLogin SuccessEND ');
                //} catch (e) {
                //console.log('error occured', e);
                //}
            })
            .catch((err) => {
                console.log('UserLogin Error ', err);
                setIsAuthenticated(false);
                if (err.response) {
                    setError(err.response.data.message);
                } else {
                    setError('Unknown error occurred!');
                }
            })
            .finally(() => {
                console.log('UserLogin FINISHED ', isAuthenticated);
                setLoading(false);
            });
    }

    function logout(): void {
        setLoading(true);
        localStorage.removeItem('token');
        localStorage.removeItem('kov');
        localStorage.removeItem('vascoUserID');
        setIsAuthenticated(false);
        setLoading(false);
    }

    function profile(): void {
        setLoading(true);
        getUserProfile()
            .then((res) => {
                //const { id, email, userType, username, ethWallet } = res.data;
                const kov = localStorage.getItem('kov');
                if (kov === null || kov === '') {
                    logout();
                    return;
                }
                res.kov = kov;
                console.log('USer PRofile', res);

                setCurrentUser(res);
                setIsAuthenticated(true);
            })
            .catch((err) => {
                setError(err);
                localStorage.clear();
                setIsAuthenticated(false);
            })
            .then(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (localStorage.getItem('vascoUserID') != null) {
            console.log('Authentication effect', isAuthenticated);
            console.log('Current user: ', currentUser);
            profile();
            setIsAuthenticated(true);
        }
    }, []);

    const value = {
        isAuthenticated: isAuthenticated,
        currentUser: currentUser,
        loading: loading,
        error: error,
        login: login,
        logout: logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
