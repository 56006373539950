import { DROPDOWN_PLACEHOLDER_TEXT } from 'config/custom-constants';
import React, { ReactElement } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { DropdownSelectableOptions } from 'types/Types';

export type DropdownSelectProps = {
    value: string;
    label: string;
    options: DropdownSelectableOptions[];
    isValid: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    onSelectedValue: React.Dispatch<React.SetStateAction<string>>;
};

const DropdownSelect = ({
    value,
    label,
    options,
    isValid,
    isLoading,
    isDisabled,
    onSelectedValue,
}: DropdownSelectProps): ReactElement => {
    return (
        <DropdownWrapper>
            <DropdownLabel>{label}</DropdownLabel>
            <DropdownList
                placeholder={value !== '' ? value : DROPDOWN_PLACEHOLDER_TEXT}
                classNamePrefix="react-select"
                value={value}
                className={isValid ? 'valid' : 'invalid'}
                options={options}
                isLoading={isLoading}
                isDisabled={isDisabled}
                defaultValue={options[0]}
                onChange={(opt: DropdownSelectableOptions) => {
                    onSelectedValue(opt.value);
                }}
            />
        </DropdownWrapper>
    );
};

export default DropdownSelect;

const DropdownWrapper = styled.span`
    display: flex;
    flex-direction: column;
    margin: 0 px;
    position: relative;
    width: 100%;
`;

const DropdownLabel = styled.span`
    font-size: 10pt;
    color: var(--color);
    position: absolute;
    transition: all 0.2s ease;
    z-index: 601;
    left: 14px;
    top: 14px;
    transform: translateY(-24px) translateX(-6px);
    background: #fff;
    padding: 0 8px;
`;

const DropdownList = styled(Select)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;
    z-index: 600;

    &.valid {
        .react-select-container {
            background-color: blue;
        }
    }
    &.invalid {
        background-color: #ffaaaa;
        .react-select__control {
            background-color: #ffaaaa;
        }
    }
    .react-select__indicator-separator {
        background-color: black;
    }

    .react-select__indicator {
        color: black;
    }
`;
