import React, { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import styled from 'styled-components';
import ChangePassword from 'ui/organisms/ChangePassword/ChangePassword';
import { UpdateUserPasswordRequest } from '../../../types/Types';
import { updateUserPassword } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

export type ChangePasswordFunctionProps = {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
};

const Profile = (): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [currentAction, setCurrentAction] = useState(0);

    const changePassword = ({ oldPassword, newPassword, newPasswordRepeat }: ChangePasswordFunctionProps) => {
        setLoading(true);

        const data: UpdateUserPasswordRequest = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        updateUserPassword(data)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                setCurrentAction(0);
            });
    };

    const cancel = () => {
        setCurrentAction(0);
    };

    return (
        <LoadingAnimationWrapper loading={loading}>
            <ProfileWrapper>
                {currentAction === 0 && (
                    <div>
                        <div>
                            {t('profile.username')} {currentUser.username}
                        </div>
                        <div>
                            {t('profile.email')} {currentUser.email}
                        </div>
                        <div>
                            {t('profile.userType')} {currentUser.userType}
                        </div>
                        {Object.keys(currentUser.ethWallet).length !== 0 && (
                            <div>
                                {t('profile.ethAddress')} {currentUser.ethWallet.address}
                            </div>
                        )}
                        <Button
                            text={t('profile.changePassword')}
                            btnType="primary"
                            onClick={() => setCurrentAction(1)}
                        />
                    </div>
                )}
                {currentAction === 1 && <ChangePassword changePassword={changePassword} cancel={cancel} />}
            </ProfileWrapper>
        </LoadingAnimationWrapper>
    );
};

export default Profile;

const ProfileWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;
