import Input from 'ui/molecules/Input/Input';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NewItem } from 'types/Types';
import { Title } from '../../../atoms/Title';
import { Button } from '../../../atoms/Button';
import { MdAddCircle } from 'react-icons/md';
import { createNewItem } from '../../../../services/sled2-api';
import { showToast } from '../../../atoms/Toast/Toast';
import { useTranslation } from 'react-i18next';

const initialNewItemState: NewItem = {
    name: '',
    unit: '',
    price: 0,
    price_with_tax: 0,
    taxRate: 0,
};

const CreateNewItem = (): JSX.Element => {
    const { t } = useTranslation();
    const [newItem, setNewItem] = useState<NewItem>(initialNewItemState);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewItem({ ...newItem, name: e.target.value });
    };
    const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewItem({ ...newItem, unit: e.target.value });
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!Number.isNaN(e.target.value)) {
            let parsed = Number.parseFloat(e.target.value).toFixed(2);
            const num = (Number.parseFloat(parsed) * 100).toString().slice(0, 10);
            parsed = (Number.parseFloat(num) / 100).toFixed(2);
            setNewItem({ ...newItem, price: Number.parseFloat(parsed) });
        }
    };
    const handlePriceWithTaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!Number.isNaN(e.target.value)) {
            let parsed = Number.parseFloat(e.target.value).toFixed(2);
            const num = (Number.parseFloat(parsed) * 100).toString().slice(0, 10);
            parsed = (Number.parseFloat(num) / 100).toFixed(2);
            setNewItem({ ...newItem, price_with_tax: Number.parseFloat(parsed) });
        }
    };
    const handleTaxRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!Number.isNaN(e.target.value)) {
            let parsed = Number.parseFloat(e.target.value).toFixed(2);
            const num = (Number.parseFloat(parsed) * 100).toString().slice(0, 5);
            parsed = (Number.parseFloat(num) / 100).toFixed(2);
            setNewItem({ ...newItem, taxRate: Number.parseFloat(parsed) });
        }
    };

    const addNewItem = () => {
        createNewItem(newItem)
            .then((res) => {
                console.log('Response', res);
                showToast({
                    position: 'top-right',
                    message: t('admin.createNewItem.successMessage'),
                    type: 'success',
                    BCtxHash: res.txLink,
                });
                setNewItem(initialNewItemState);
            })
            .catch((error) => {
                console.error('Error occured', error);
                showToast({
                    position: 'top-right',
                    message: t('admin.createNewItem.errorMessage'),
                    type: 'danger',
                    BCtxHash: error,
                });
            });
    };

    return (
        <CreateNewUserWrapper>
            <Title text={t('admin.createNewItem.title')} subtext=""></Title>
            <span style={{ margin: '10px 20px 10px 20px' }}>
                Polja označena z <span style={{ color: 'red' }}>*</span> so obvezna
            </span>
            <StyledInput
                label={t('admin.createNewItem.name')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newItem.name}
                onChange={handleNameChange}
                className={newItem.name != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewItem.unit')}
                type="textarea"
                required={true}
                maxLength={10}
                value={newItem.unit}
                onChange={handleUnitChange}
                className={newItem.unit != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewItem.price')}
                type="number"
                step="0.1"
                pattern="/^-?\d{1,8}\.?\d{2}$/"
                required={true}
                value={newItem.price}
                onChange={handlePriceChange}
                className={newItem.price != 0 ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewItem.priceWithTax')}
                type="number"
                step="0.01"
                required={true}
                maxLength={100}
                value={newItem.price_with_tax}
                onChange={handlePriceWithTaxChange}
                className={newItem.price_with_tax != 0 ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewItem.taxRate')}
                type="number"
                step="0.01"
                required={true}
                maxLength={100}
                value={newItem.taxRate}
                onChange={handleTaxRateChange}
                className={newItem.taxRate != 0 ? 'valid' : 'invalid'}
            />
            <ContainerButton>
                <Button
                    onClick={addNewItem}
                    disabled={!(newItem.name != '' && newItem.unit != '')}
                    iconPosition="left"
                    icon={<MdAddCircle />}
                    text={t('admin.createNewItem.buttonAddNewPlus')}
                    btnType="primary"
                />
            </ContainerButton>
        </CreateNewUserWrapper>
    );
};

export default CreateNewItem;

const CreateNewUserWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
        margin-left: 33%;
        margin-right: 33%;
    }
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;

    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
`;

const ContainerButton = styled.div`
    width: 100%;
`;
