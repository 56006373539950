import Input from 'ui/molecules/Input/Input';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NewChain } from 'types/Types';
import { Title } from '../../../atoms/Title';
import { Button } from '../../../atoms/Button';
import { GoCheck } from 'react-icons/go';
import { createNewChain } from '../../../../services/sled2-api';
import { showToast } from '../../../atoms/Toast/Toast';
import { useTranslation } from 'react-i18next';

const initialNewChainState: NewChain = {
    chainName: '',
    chainCountry: '',
    chainAdminName: '',
    chainAdminPassword: '',
    chainAdminEmail: '',
};

const CreateNewChain = (): JSX.Element => {
    const { t } = useTranslation();
    const [newChain, setNewChain] = useState<NewChain>(initialNewChainState);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewChain({ ...newChain, chainName: e.target.value });
    };
    const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewChain({ ...newChain, chainCountry: e.target.value });
    };
    const handleAdminEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewChain({ ...newChain, chainAdminEmail: e.target.value });
    };
    const handleAdminNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewChain({ ...newChain, chainAdminName: e.target.value });
    };
    const handleAdminPassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewChain({ ...newChain, chainAdminPassword: e.target.value });
    };

    const addNewChain = () => {
        createNewChain(newChain)
            .then((res) => {
                console.log('Response', res);
                showToast({
                    position: 'top-right',
                    message: t('admin.createNewChain.successMessage'),
                    type: 'success',
                    BCtxHash: res.txLink,
                });
            })
            .catch((error) => {
                console.error('Error occured', error);
                showToast({
                    position: 'top-right',
                    message: t('admin.createNewChain.errorMessage'),
                    type: 'danger',
                    BCtxHash: error,
                });
            });
    };

    return (
        <CreateNewChainWrapper>
            <Title text={t('admin.createNewChain.title')} subtext=""></Title>
            <span style={{ margin: '10px 20px 10px 20px' }}>
                Polja označena z <span style={{ color: 'red' }}>*</span> so obvezna
            </span>
            <StyledInput
                label={t('admin.createNewChain.name')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newChain.chainName}
                onChange={handleNameChange}
                className={newChain.chainName != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewChain.country')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newChain.chainCountry}
                onChange={handleCountryChange}
                className={newChain.chainCountry != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewChain.adminEmail')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newChain.chainAdminEmail}
                onChange={handleAdminEmailChange}
                className={newChain.chainAdminEmail != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewChain.adminName')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newChain.chainAdminName}
                onChange={handleAdminNameChange}
                className={newChain.chainAdminName != '' ? 'valid' : 'invalid'}
            />
            <StyledInput
                label={t('admin.createNewChain.adminPassword')}
                type="textarea"
                required={true}
                maxLength={100}
                value={newChain.chainAdminPassword}
                onChange={handleAdminPassChange}
                className={newChain.chainAdminPassword != '' ? 'valid' : 'invalid'}
            />
            <ContainerButton>
                <Button
                    onClick={addNewChain}
                    disabled={
                        !(
                            newChain.chainName != '' &&
                            newChain.chainCountry != '' &&
                            newChain.chainAdminName != '' &&
                            newChain.chainAdminPassword != ''
                        )
                    }
                    iconPosition="left"
                    icon={<GoCheck />}
                    text={t('admin.createNewChain.buttonAddNewPlus')}
                    btnType="primary"
                />
            </ContainerButton>
        </CreateNewChainWrapper>
    );
};

export default CreateNewChain;

const CreateNewChainWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
        margin-left: 33%;
        margin-right: 33%;
    }
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;

    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
`;

const ContainerButton = styled.div`
    width: 100%;
`;
