import Input from 'ui/molecules/Input/Input';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadedFiles, SowingData } from 'types/Types';
import { FileDropzone2 } from 'ui/molecules/FileDropzone2';
import DropdownSelect from 'ui/atoms/DropdownSelect/DropdownSelect';
import { SOWING_UPDATE_DESCRIPTION_OPTIONS } from 'config/custom-constants';
import { getCropsNamesFromSowing } from '../../../helpers/helper-functions';
import { useTranslation } from 'react-i18next';

type CreateSowingProps = {
    sowingData: SowingData;
    setAllInputIsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setSowingData: React.Dispatch<React.SetStateAction<SowingData>>;
    setIpfsPaths: React.Dispatch<React.SetStateAction<string[]>>;
    setLoadedFiles: React.Dispatch<React.SetStateAction<LoadedFiles[]>>;
};

const UpdateSowing = ({
    sowingData,
    setAllInputIsValid,
    setSowingData,
    setIpfsPaths,
    setLoadedFiles,
}: CreateSowingProps): ReactElement => {
    const { t } = useTranslation();
    const [minDate, setMinDate] = useState<string>(sowingData.date);
    const [oldComment, setOldComment] = useState<string>(sowingData.comment);
    const [startingComment, setComment] = useState<string>('');
    const [commentValid, setCommentValid] = useState<boolean>(false);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSowingData({ ...sowingData, date: e.target.value });
    };

    useEffect(() => {
        if (startingComment != '') {
            setSowingData({ ...sowingData, comment: startingComment });
            console.log('validation of comment setting to true', startingComment);
            setAllInputIsValid(true);
            setCommentValid(true);
        } else {
            setAllInputIsValid(false);
            setCommentValid(false);
        }
    }, [startingComment]);

    useEffect(() => {
        if (startingComment === '') {
            console.log('validation of comment setting to false');
            setAllInputIsValid(false);
            setCommentValid(false);
        }
    }, []);

    return (
        <CreateSowingWrapper>
            {sowingData.crop && (
                <>
                    <StyledInput
                        label={t('sowingUpdate.common.name')}
                        type="text"
                        disabled
                        value={getCropsNamesFromSowing(sowingData)}
                    />
                    <StyledInput
                        label={t('sowingUpdate.common.nationalLocationId')}
                        type="textarea"
                        disabled
                        value={sowingData.GERK}
                    />
                    <StyledInput
                        label={t('sowingUpdate.common.oldDescription')}
                        type="textarea"
                        disabled
                        value={oldComment}
                    />
                    <DropdownSelect
                        value={startingComment}
                        label={t('sowingUpdate.common.description')}
                        isValid={commentValid}
                        isLoading={false}
                        isDisabled={false}
                        options={SOWING_UPDATE_DESCRIPTION_OPTIONS}
                        onSelectedValue={setComment}
                    />
                    <StyledInput
                        label={t('sowingUpdate.common.description')}
                        type="date"
                        value={sowingData.date}
                        onChange={handleDateChange}
                        min={minDate}
                        max={new Date().toISOString().split('T')[0]}
                    />
                    <FileDropzone2 setIpfsPaths={setIpfsPaths} setLoadedFiles={setLoadedFiles} />
                </>
            )}
        </CreateSowingWrapper>
    );
};

export default UpdateSowing;

const CreateSowingWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;

    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
`;
