import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { User } from 'types/Types';
import { useApiState } from 'hooks/useApiState';
import { getUserActivityLogs } from 'services/blockis-api';
import { ACTIVITY_MAP } from 'config/custom-constants';
import { formatTimestampToString } from 'helpers/helper-functions';
import { useTranslation } from 'react-i18next';

export type CheckUserActivitiesProps = {
    user: User;
};

export type UserActivityLog = {
    actionType: string;
    images?: [];
    length: number;
    ipfs: string;
    timestamp: string;
    tx: string;
    user: User;
    _id: string;
};

const CheckUserActivities = ({ user }: CheckUserActivitiesProps): JSX.Element => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [newUser, setNewUser] = useState<any>(null);
    const [currentAction, setCurrentAction] = useState(0);
    const [userActivityLog, setUserActivityLogs] = useState<UserActivityLog[]>([]);

    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const cancel = () => {
        setCurrentAction(0);
    };

    const getUserActivityLogsByUserId = (userId: number) => {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 2);
        const parameters = {
            end: new Date().toISOString(),
            userId: userId,
            start: startDate.toISOString(),
        };
        console.log('DATE time:', new Date().toISOString());
        console.log('DATE time:', new Date().toUTCString());
        console.log('DATE time:', new Date().toLocaleDateString());
        getUserActivityLogs(currentUser.token, parameters)
            .then((response: UserActivityLog[]) => {
                response.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
                setUserActivityLogs(response);
                console.log('Get user logs activity return resposne:', response);
                console.log('Get user log:', response[0].actionType);
            })
            .catch((err) => {
                console.log('Get user logs activity return error:', err);
            });
    };

    useEffect(() => {
        getUserActivityLogsByUserId(user._id);
    }, []);

    return (
        <Wrapper>
            {currentAction === 0 && (
                <div>
                    <div>
                        {t('admin.activityLog.username')} {user.username}
                    </div>
                    <div>
                        {t('admin.activityLog.email')}Elektronski naslov: {user.email}
                    </div>
                    <div>
                        {t('admin.activityLog.userTypes')}Tip uporabnika: {user.userType}
                    </div>
                    <div>
                        {t('admin.activityLog.userId')}ID uporabnika: {user._id}
                    </div>
                    <div>{t('admin.activityLog.activities')}Aktivnosti:</div>
                    {userActivityLog.map((activityLog) => (
                        <ActivityWrapper key={activityLog._id}>
                            {formatTimestampToString(activityLog.timestamp, true)} -{''}
                            {ACTIVITY_MAP.get(activityLog.actionType)?.toString()}
                        </ActivityWrapper>
                    ))}
                    {user.ethWallet && (
                        <div>
                            {t('profile.ethAddress')} {user.ethWallet.address}
                        </div>
                    )}
                </div>
            )}
        </Wrapper>
    );
};

export default CheckUserActivities;

const Wrapper = styled.div`
    margin: 10px;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ActivityWrapper = styled.div`
    margin-left: 10px;
    font-weight: 700;
    font-size: 10pt;
`;
