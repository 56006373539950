import Input from 'ui/molecules/Input/Input';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadedFiles, SowingData } from 'types/Types';
import { FileDropzone2 } from 'ui/molecules/FileDropzone2';
import { SOWING_CREATE_COMMENT_OPTIONS } from 'config/custom-constants';
import DropdownSelect from 'ui/atoms/DropdownSelect/DropdownSelect';
import { getCropsNamesFromSowing } from 'helpers/helper-functions';
import { useTranslation } from 'react-i18next';

type CreateSowingProps = {
    sowingData: SowingData;
    setAllInputIsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setSowingData: React.Dispatch<React.SetStateAction<SowingData>>;
    setIpfsPaths: React.Dispatch<React.SetStateAction<string[]>>;
    setLoadedFiles: React.Dispatch<React.SetStateAction<LoadedFiles[]>>;
};

const CreateSowing = ({
    sowingData,
    setAllInputIsValid,
    setSowingData,
    setIpfsPaths,
    setLoadedFiles,
}: CreateSowingProps): ReactElement => {
    const { t } = useTranslation();
    const [gerkValue, setGerkValue] = useState<string>(sowingData.GERK);
    const [gerkValueValid, setGerkValueValid] = useState<boolean>(sowingData.GERK != '' ? true : false);
    const [commentValue, setCommentValue] = useState<string>(sowingData.comment);

    const handleGerkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAllInputIsValid(e.target.validity.valid);
        console.log('Is gerk value valid:', e.target.validity.valid);
        setGerkValue(e.target.value);

        if (e.target.validity.valid) {
            setGerkValueValid(true);
            console.log('Setting gerk value:', e.target.value);
            setSowingData({ ...sowingData, GERK: e.target.value });
        } else {
            setGerkValueValid(false);
            setSowingData({ ...sowingData, GERK: '' });
        }
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSowingData({ ...sowingData, date: e.target.value });
    };

    useEffect(() => {
        setSowingData({ ...sowingData, comment: commentValue });
    }, [commentValue]);

    return (
        <CreateSowingWrapper>
            {sowingData.crop && (
                <>
                    <StyledInput
                        label={t('sowing.common.createSowingItemName')}
                        type="text"
                        disabled
                        value={getCropsNamesFromSowing(sowingData)}
                    />
                    <StyledInput
                        label={t('sowing.common.nationalLocationId')}
                        type="textarea"
                        required={true}
                        maxLength={7}
                        pattern="\d{4,7}"
                        value={gerkValue}
                        onChange={handleGerkChange}
                        className={gerkValueValid ? 'valid' : 'invalid'}
                    />
                    <DropdownSelect
                        value={commentValue}
                        label={t('sowing.common.comment')}
                        isValid={true}
                        isLoading={false}
                        isDisabled={false}
                        options={SOWING_CREATE_COMMENT_OPTIONS}
                        onSelectedValue={setCommentValue}
                    />
                    <StyledInput
                        label={t('sowing.common.date')}
                        type="date"
                        value={sowingData.date}
                        onChange={handleDateChange}
                        max={new Date().toISOString().split('T')[0]}
                    />
                    <FileDropzone2 setIpfsPaths={setIpfsPaths} setLoadedFiles={setLoadedFiles} />
                </>
            )}
            <span>
                {t('commonText.requiredFieldDisclaimerPart1')}
                <span style={{ color: 'red' }}>*</span>
                {t('commonText.requiredFieldDisclaimerPart2')}
            </span>
        </CreateSowingWrapper>
    );
};

export default CreateSowing;

const CreateSowingWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;

    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
`;
