import { SowingData } from '../types/Types';

function dateString(date: Date, showTime: boolean): string {
    const time: string = showTime ? ' ' + date.toLocaleTimeString('sl-SI') : '';
    return date.toISOString().split('T')[0] + time;
}

export function formatTimestampToString(timestamp: string, showTime: boolean) {
    const date = new Date(timestamp);
    return dateString(date, showTime);
}

export function formatDateToString(date: Date, showTime: boolean) {
    return dateString(date, showTime);
}

export function formatTimestampToDateID(timestamp: number): string {
    const date = new Date(timestamp);
    let id = dateString(date, true);
    id = id.replaceAll('-', '');
    id = id.replaceAll(' ', '');
    id = id.replaceAll(':', '');

    return id;
}

export function redirectToBCExplorer(BCtxHash: string) {
    console.log(BCtxHash);
    window.open(BCtxHash, '_blank');
}

export function getCropsNamesFromSowing(sowing: SowingData): string {
    let crops = '';
    for (const it of sowing.crop) {
        if (crops == '') {
            crops = it.name;
        } else {
            crops = crops + ', ' + it.name;
        }
    }
    return crops;
}

export function getArrayOfCropsIdsForSowing(sowing: SowingData): string[] {
    const crops: string[] = [];
    for (const it of sowing.crop) {
        crops.push(it._id.toString());
    }
    return crops;
}
