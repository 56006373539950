import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Crop, LoadedFiles, SowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import CreateSowing from 'ui/organisms/CreateSowing/CreateSowing';
import ConfirmSowing from 'ui/organisms/ConfirmSowing/ConfirmSowing';
import { Title } from 'ui/atoms/Title';
import { AlertPopup } from 'ui/atoms/AlertPopup';
import SearchCropsMulti from '../../organisms/SearchCropsMulti/SearchCropsMulti';
import { getItems, getUserItems } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

const initialStateSowingData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 0,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
    activity_id: 0,
};

const Sowing: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [crops, setCrops] = useState<Crop[]>([]);
    const [selectedCrops, setSelectedCrops] = useState<Crop[]>([]);
    const [sowingData, setSowingData] = useState<SowingData>(initialStateSowingData);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [files, setLoadedFiles] = useState<LoadedFiles[]>([]);
    const [txLink, setTxLink] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [isValid, isAllInputValid] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(true);

    const checkIfImagesNeedToRemoved = () => {
        if (step == 2) {
            setLoadedFiles([]);
        }
        stepDec();
    };

    /*const getCropFromId = () => {
        let data = null;
        for (let i = 0; i < crops.length; i++) {
            if (selectedCrops.includes(crops[i])) {
                data = crops[i];
                break;
            }
        }
        return data;
    };*/

    const setDefaultCrops = () => {
        setLoading(true);
        getItems(parseInt(currentUser.sourceSystemId))
            .then((res) => {
                //console.log('Response', res);
                setCrops(res);
            })
            .finally(() => setLoading(false));
    };

    const showImageAlertOrProceed = () => {
        //console.log('showImageAlertOrProceed');
        if (step === 1 && files.length === 0) {
            if (!confirmed) {
                setShowAlert(true);
            } else {
                setConfirmed(false);
                stepInc();
            }
        } else {
            stepInc();
        }
    };

    useEffect(() => {
        getUserItems(currentUser.id)
            .then((response) => {
                if (response.length != 0) {
                    setCrops(response);
                } else {
                    setDefaultCrops();
                }
            })
            .catch((err) => console.error('Get User Items error: ', err));
    }, []);

    useEffect(() => {
        console.log('1_Log valid:', isValid);
        console.log('1_step', step);
        console.log('1_gerk_pid', sowingData.GERK);
        console.log('1_gerk_pid', step == 1 && sowingData.GERK == '' && !isValid);
    }, [isValid]);

    useEffect(() => {
        setSowingData({ ...sowingData, crop: selectedCrops });
    }, [selectedCrops]);

    useEffect(() => {
        setShowAlert(false);
        if (confirmed) {
            if (step === 1) {
                setConfirmed(false);
                stepInc();
            }
        }
    }, [confirmed]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title text={t('sowing.title')} subtext={t('sowing.chooseCropSubtitle')} />
                                            <SearchCropsMulti
                                                onSelectionChanged={setSelectedCrops}
                                                crops={crops}
                                                selected={selectedCrops}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && selectedCrops != undefined && selectedCrops[0] != undefined && (
                                        <StepWrapper>
                                            <Title
                                                text={t('sowing.title')}
                                                subtext={t('sowing.insertSowingDataSubtitle')}
                                            />
                                            <CreateSowing
                                                sowingData={sowingData}
                                                setAllInputIsValid={isAllInputValid}
                                                setSowingData={setSowingData}
                                                setIpfsPaths={setIpfsPaths}
                                                setLoadedFiles={setLoadedFiles}
                                            />
                                            <AlertPopup
                                                title={t('alertPopup.addSowingImageDesc')}
                                                message={t('alertPopup.addSowingImageMessage')}
                                                isShown={showAlert}
                                                confirmed={confirmed}
                                                declineButtonText={t('alertPopup.declineButtonText')}
                                                confirmeButtonText={t('alertPopup.confirmButtonText')}
                                                onDeclined={setShowAlert}
                                                onConfirmed={setConfirmed}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 2 && (
                                        <StepWrapper>
                                            <Title
                                                text={t('sowing.title')}
                                                subtext={t('sowing.confirmSowingSubtitle')}
                                            />
                                            <ConfirmSowing
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={false}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            onClick={checkIfImagesNeedToRemoved}
                                            disabled={step === 0}
                                            iconPosition="left"
                                            icon={<GoArrowLeft />}
                                            text={t('navigation.back')}
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            onClick={showImageAlertOrProceed}
                                            disabled={
                                                !(
                                                    selectedCrops != null &&
                                                    selectedCrops != undefined &&
                                                    selectedCrops[0] != undefined
                                                ) ||
                                                step === 2 ||
                                                (step == 1 && sowingData.GERK == '' && !isValid)
                                            }
                                            iconPosition="right"
                                            icon={<GoArrowRight />}
                                            text={t('navigation.next')}
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message={t('sowing.createSowingSuccessMessage')} txLink={txLink} />
                                {files.length != 0 && (
                                    <>
                                        {imageLoading && (
                                            <>
                                                <SuccessMessage message={t('sowing.createSowingSuccessImageMessage')} />
                                            </>
                                        )}
                                        <LoadingAnimationWrapper loading={imageLoading}>
                                            <SuccessMessage
                                                message={t('sowing.createSowingSuccessImageAddedMessage')}
                                            />
                                        </LoadingAnimationWrapper>
                                    </>
                                )}
                                {files.length == 0 && (
                                    <>
                                        {imageLoading && (
                                            <>
                                                <SuccessMessage
                                                    message={t('sowing.createSowingSuccessDescriptionMessage')}
                                                />
                                            </>
                                        )}
                                        <LoadingAnimationWrapper loading={imageLoading}>
                                            <SuccessMessage
                                                message={t('sowing.createSowingSuccessDescriptionAddedMessage')}
                                            />
                                        </LoadingAnimationWrapper>
                                    </>
                                )}
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            text={t('navigation.addOneMore')}
                                            onClick={() => window.location.reload()}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text={t('navigation.moveToAList')}
                                            onClick={() => history.push('/sowingupdate')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text={t('navigation.moveToAHome')}
                                            onClick={() => history.push('/')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default Sowing;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    @media screen and (max-width: 768px) {
        margin: 0 0 0 0;
    }
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
