import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Crop } from 'types/Types';
import { Input } from 'ui/molecules/Input';
import ListCropsMulti from '../List/CropsMulti/ListCropsMulti';
import { useTranslation } from 'react-i18next';

type SearchCropsMultiProps = {
    crops: Crop[];
    selected: Crop[];
    onSelectionChanged: React.Dispatch<React.SetStateAction<Crop[]>>;
};

const SearchCropsMulti = ({ crops, selected, onSelectionChanged }: SearchCropsMultiProps): ReactElement => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [filteredCrops, setFilteredCrops] = useState<Crop[]>([]);
    const [selectedCrops, setSelectedCrops] = useState<Crop[]>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        //console.log('Logged selection changed', selectedSowing);
        onSelectionChanged(selectedCrops);
    }, [selectedCrops]);

    useEffect(() => {
        setFilteredCrops(crops);
    }, [crops]);

    useEffect(() => {
        setFilteredCrops(
            crops.filter((crop) => {
                if (crop.name) return crop.name.toLowerCase().includes(search.toLowerCase());
            }),
        );
    }, [search]);

    return (
        <SearchCropsWrapper>
            <SearchInput type="text" value={search} label={t('search.crops')} onChange={handleInputChange} />
            <ListWrapper>
                <ListCropsMulti crops={filteredCrops} onSelectionChanged={setSelectedCrops} selected={selected} />
            </ListWrapper>
        </SearchCropsWrapper>
    );
};

export default SearchCropsMulti;

const SearchCropsWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin: 10px;
    flex-direction: column;
`;

const SearchInput = styled(Input)`
    margin-bottom: 15px;
    border: 1px solid black;
`;

const ListWrapper = styled.div`
    flex: 1 1 auto;
`;
