import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm, FormikFormProps } from 'formik';
import { Redirect } from 'react-router';
import { useAuth } from '../../../contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import { Input } from 'ui/molecules/Input';
import styled from 'styled-components';
import { RiLockPasswordLine, RiUser2Fill } from 'react-icons/ri';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useApiState } from 'hooks/useApiState';
import Select from 'react-select';
import { KOV, OptionsKOV, VASCOPartner } from 'types/Types';
import { getAllKOV } from '../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

const Login = (): JSX.Element => {
    const { t } = useTranslation();
    const { login, isAuthenticated, error: errorLogin, loading: authLoading } = useAuth();
    //if (isAuthenticated) return <Redirect to="/home" />;

    const [optionsKOV, setOptionsKOV] = useState<OptionsKOV[]>([]);
    const { loading, error, setLoading, setError } = useApiState();
    const [initialOptionKov, setInitialOptionKov] = useState<OptionsKOV>({ value: '', label: '' });

    useEffect(() => {
        setLoading(true);
        getAllKOV()
            .then((res: OptionsKOV[]) => {
                console.log('test kov');
                setOptionsKOV(res);
                console.log('Option KOV: ', optionsKOV);
                setInitialOptionKov(res[0]);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => setLoading(false));
    }, []);

    if (isAuthenticated != null && isAuthenticated) return <Redirect to="/home" />;

    return (
        <LoadingAnimationWrapper loading={authLoading}>
            <Wrapper>
                <CardWrapper>
                    <Card>
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                                kov: initialOptionKov,
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                login(values);
                                setSubmitting(false);
                            }}
                        >
                            {({ submitForm, isSubmitting, values, setFieldValue }) => (
                                <Form>
                                    <h1>{t('login.title')}</h1>
                                    <p>{t('login.subtitle')}</p>
                                    <FormRow>
                                        <InputGroup>
                                            <IconWrapper>
                                                <RiUser2Fill />
                                            </IconWrapper>
                                            <Input
                                                name="username"
                                                type="text"
                                                label={t('login.username')}
                                                value={values.username}
                                                onChange={(e) => setFieldValue('username', e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormRow>
                                    <FormRow>
                                        <InputGroup>
                                            <IconWrapper>
                                                <RiLockPasswordLine />
                                            </IconWrapper>
                                            <Input
                                                name="password"
                                                type="password"
                                                label={t('login.password')}
                                                value={values.password}
                                                onChange={(e) => setFieldValue('password', e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormRow>
                                    {errorLogin !== null && errorLogin !== '' && (
                                        <LoginErrorMessage>
                                            <span>{t('login.errorMessage')}</span>
                                        </LoginErrorMessage>
                                    )}
                                    <FormRow>
                                        {optionsKOV.length !== 0 && (
                                            <SelectKOV
                                                options={optionsKOV}
                                                isLoading={loading}
                                                isDisabled={loading}
                                                value={(values.kov = optionsKOV[0])}
                                                onChange={(opt: OptionsKOV, e: string) => {
                                                    setFieldValue('kov', opt);
                                                }}
                                            />
                                        )}
                                    </FormRow>
                                    <Button
                                        btnType="primary"
                                        disabled={isSubmitting || loading || values.kov.value === ''}
                                        onClick={submitForm}
                                        text={t('login.loginButtonText')}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </CardWrapper>
            </Wrapper>
        </LoadingAnimationWrapper>
    );
};

export default Login;

const Wrapper = styled.div`
    flex: 1;
    text-align: center;
    max-width: 1140px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
`;

const LoginErrorMessage = styled.div`
    flex: 1 0;
    padding: 1em;
    background-color: #d9534f;
    color: white;
    border-radius: 0.25rem;
    margin-bottom: 1em;
`;

const CardWrapper = styled.div`
    background-color: #fff;
    border: 1px solid #d8dbe0;
`;

const Card = styled.div`
    flex: 1 0;
    padding: 2.75em;
`;

const Form = styled(FormikForm)<FormikFormProps>`
    & > h1 {
        margin-bottom: 8px;
    }

    & > p {
        color: var(--muted-color);
        margin-bottom: 2rem;
    }
`;

const InputGroup = styled.div`
    display: flex;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;

    & > div > input {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    border-radius: 0.25rem;
    color: #768192;
    background-color: var(--light);
    border: 1px solid #d8dbe0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

const SelectKOV = styled(Select)`
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
`;
