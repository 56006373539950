import React, { ReactElement, useEffect, useState } from 'react';
import { Scanner } from 'ui/organisms/QRCode';
import styled from 'styled-components';
import { FetchedVCDataMulti, FetchedVCDataMultiNew } from 'types/Types';
import ConfirmPickup from 'ui/organisms/ConfirmPickup/ConfirmPickup';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { useHistory } from 'react-router-dom';
import { getVcDataByScaningQrCode } from '../../../../services/sled2-api';
import { useTranslation } from 'react-i18next';

const PickupScan = (): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentUser } = useAuth();
    const [result, setResult] = useState<string | null>(null);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [fetchedVCData, setFetchedVCData] = useState<FetchedVCDataMultiNew | null>(null);
    const [txLink, setTxLink] = useState<string | null>(null);

    const getVCData = () => {
        if (result) {
            setLoading(true);
            getVcDataByScaningQrCode(result)
                .then((res) => {
                    setFetchedVCData(res);
                })
                .catch((err) => {
                    console.log(err.response.data);
                })
                .finally(() => setLoading(false));
        } else {
            setFetchedVCData(null);
        }
    };

    useEffect(() => {
        getVCData();
    }, [result]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <WrapperScanner>
                {!error && (
                    <>
                        {!success && (
                            <>
                                {!fetchedVCData && <Scanner result={result} setResult={setResult} />}
                                {!loading && fetchedVCData && (
                                    <div>
                                        <ConfirmPickup
                                            fetchedVCData={fetchedVCData}
                                            setResult={setResult}
                                            setError={setError}
                                            setLoading={setLoading}
                                            setSuccess={setSuccess}
                                            setTxLink={setTxLink}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message={t('pickup.successMessage')} txLink={txLink} />
                                <Button
                                    text={t('navigation.moveToAHome')}
                                    onClick={() => history.push('/')}
                                    btnType="primary"
                                />
                            </>
                        )}
                    </>
                )}
            </WrapperScanner>
        </LoadingAnimationWrapper>
    );
};

export default PickupScan;

const WrapperScanner = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
